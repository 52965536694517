import { APIService } from "services";

export const getArchivio = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllArchivio(pageNo,pageSize);
    }
    else{
        try {
            res = await searchArchivio(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getArchivio_Utente = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllArchivio_Utente(pageNo,pageSize);
    }
    else{
        try {
            res = await searchArchivio_Utente(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getAllDocumenti = (iddoc, actionto) => {
return APIService.api().get(`/documenti/visualizza_documenti.php?service=${iddoc}&actionto=${actionto}`)
}

export const getAllArchivio = (pageno,pagesize) => {
return APIService.api().get(`/archivio/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllArchivioDaleggere = () => {
return APIService.api().get(`/archivio/read_file_da_leggere.php`)
}

export const getAllArchivio_Utente = (pageno,pagesize) => {
return APIService.api().get(`/archivio/read_by_utente.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneArchivio = (id) => {
return APIService.api().get(`/archivio/read_one.php?id=${id}`)
}
export const searchArchivio = (key,pageno,pagesize) => {
return APIService.api().get(`/archivio/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchArchivio_Utente = (key,pageno,pagesize) => {
return APIService.api().get(`/archivio/search_utente.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addArchivio = (data) => {
return APIService.api().post(`/archivio/create.php`,data)
}
export const updateArchivio = (data) => {
return APIService.api().post(`/archivio/update.php`,data)
}
export const updateLettura = (id) => {
return APIService.api().get(`/archivio/update_lettura.php?id=${id}`)
}
export const deleteArchivio = (id_archivio) => {
return APIService.api().post(`/archivio/delete.php`,{id_archivio:id_archivio})
}
