import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setWs_LoginList, setWs_LoginMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getOneWs_Login, getWs_Login } from "services/ws_loginService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Ws_LoginForm } from "./form";
import { Ws_LoginTable } from "./table";
import { parseJwtToken } from "services/tokenService";

export const Ws_Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.ws_login);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);
  const getData = (page, pageSize, searchKey) => {
    if(jwtToken.data.id === 1){
      getWs_Login(page, pageSize, searchKey).then((response) => {
        if (response && response.records) {
          dispatch(setWs_LoginList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          dispatch(setWs_LoginMessage("Nessun dato presente in questa sezione"));
        }
      })
    }else{
      getOneWs_Login(jwtToken.data.id).then((response) => {
        if (response && response.data.document) {
          let lista = []
          lista.push(response.data.document)
          dispatch(setWs_LoginList({ pageNo: page, pageSize: pageSize, list: lista, totalCount: 1, searchKey: searchKey }));
        } else {
          dispatch(setWs_LoginMessage("Nessun dato restituito"));
        }
      })
    }
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setWs_LoginMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid">
        
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setWs_LoginMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <Ws_LoginForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Ws_LoginTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

