import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAssistenza {
id:number,
id_utente?:number,
data_richiesta:Date,
stato:number,
nome:string,
cognome:string,
azienda:string,
email:string,
telefono:string,
descrizione:string,
risposta:string
}

interface IAssistenzaData {
    list?: Array<IAssistenza>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IAssistenzaData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const assistenzaSlice = createSlice({
    name: "assistenza",
    initialState,
    reducers: {
        setAssistenzaList: (state, _action: PayloadAction<IAssistenzaData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAssistenzaToInit: (state) => {
            state = initialState;
        },
        setAssistenzaMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAssistenzaList, resetAssistenzaToInit, setAssistenzaMessage } = assistenzaSlice.actions;

export default assistenzaSlice.reducer;

