import { APIService } from "services";

export const getAssistenza = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllAssistenza(pageNo,pageSize);
    }
    else{
        try {
            res = await searchAssistenza(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getAssistenzaUtente = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllAssistenzaUtente(pageNo,pageSize);
    }
    else{
        try {
            res = await searchAssistenza(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllAssistenza = (pageno,pagesize) => {
return APIService.api().get(`/assistenza/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}

export const getAllAssistenzaUtente = (pageno,pagesize) => {
return APIService.api().get(`/assistenza/read_by_utente.php?pageno=${pageno}&pagesize=${pagesize}`)
}

export const getOneAssistenza = (id) => {
return APIService.api().get(`/assistenza/read_one.php?id=${id}`)
}
export const searchAssistenza = (key,pageno,pagesize) => {
return APIService.api().get(`/assistenza/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchAssistenzaUtente = (key,pageno,pagesize) => {
return APIService.api().get(`/assistenza/search_utente.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addAssistenza = (data) => {
return APIService.api().post(`/assistenza/create.php`,data)
}
export const updateAssistenza = (data) => {
return APIService.api().post(`/assistenza/update.php`,data)
}
export const deleteAssistenza = (id) => {
return APIService.api().post(`/assistenza/delete.php`,{id:id})
}
export const inserisciAssistenza = async (data) => {
    try{
        const response = await APIService.apiNoAuth().post(`/token/inserisci_assistenza.php`, data, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    
        return response;
    }catch(error){
    return null;
    }
    }
