import { APIService } from "services";


export const getTrovaImpresa = (dati_ricerca,pageno,pagesize) => {
    return APIService.api().post(`/ws_trovaimpresa/trova_impresa.php`, dati_ricerca);
}
export const getTrovaImpresaPec = (dati_ricerca) => {
    return APIService.api().post(`/ws_trovaimpresa/trova_impresa_pec.php?`, dati_ricerca);
}
export const getRicercaImpresaDenominazione = (dati_ricerca) => {
    return APIService.api().post(`/ws_visura/ricerca_denominazione.php?`, dati_ricerca);
}
export const getRicercaImpresaCodiceFiscale = (dati_ricerca) => {
    return APIService.api().post(`/ws_visura/ricerca_codice_fiscale.php?`, dati_ricerca);
}
export const getRicercaImpresaPartitaIva = (dati_ricerca) => {
    return APIService.api().post(`/ws_visura/ricerca_partita_iva.php?`, dati_ricerca);
}