import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITrova_Impresa {
id_utente:number,
id_abbonamento:number,
codice_abbonamento:string,
numero_anagrafiche_massimo:number,
numero_anagrafiche_residuo:number,
data_apertura:Date,
data_scadenza:Date,
attivo:number
}

interface ITrova_ImpresaData {
    list?: Array<any>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITrova_ImpresaData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const trovaImpresaSlice = createSlice({
    name: "piano_abbonamento",
    initialState,
    reducers: {
        setTrovaImpresaList: (state, _action: PayloadAction<ITrova_ImpresaData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTrovaImpresaToInit: (state) => {
            state = initialState;
        },
        setTrovaImpresaMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTrovaImpresaList, resetTrovaImpresaToInit, setTrovaImpresaMessage } = trovaImpresaSlice.actions;

export default trovaImpresaSlice.reducer;

