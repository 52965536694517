import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPiano_EstrazioniMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addPiano_Estrazioni, updatePiano_Estrazioni } from "services/piano_estrazioniService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Piano_EstrazioniForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',id_cliente:'',id_abbonamento:'',data_estrazione:'',numero_anagrafiche:'',nome_file:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updatePiano_Estrazioni(values);
                if (response) {
                    dispatch(setPiano_EstrazioniMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPiano_EstrazioniMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addPiano_Estrazioni(values);
                if (response) {
                    dispatch(setPiano_EstrazioniMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPiano_EstrazioniMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id_cliente: yup.number().required('id_cliente is required'),
id_abbonamento: yup.number().required('id_abbonamento is required'),
data_estrazione: yup.date().required('data_estrazione is required'),
numero_anagrafiche: yup.number().required('numero_anagrafiche is required'),
nome_file: yup.string().required('nome_file is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">Modifica Descrizione Estrazione
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">Ritorna alla Lista</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  {/* <Form.Group>
<label className="form -control-label">id_cliente</label>
<Form.Control type="text" name="id_cliente" className="form-control" value={formik.values.id_cliente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_cliente && !!formik.errors.id_cliente}
isValid ={!!formik.touched.id_cliente && !formik.errors.id_cliente}
></Form.Control>
{
    formik.errors.id_cliente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_cliente}
    </Form.Control.Feedback>
)}
</Form.Group> */}
{/* <Form.Group>
<label className="form -control-label">id_abbonamento</label>
<Form.Control type="text" name="id_abbonamento" className="form-control" value={formik.values.id_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_abbonamento && !!formik.errors.id_abbonamento}
isValid ={!!formik.touched.id_abbonamento && !formik.errors.id_abbonamento}
></Form.Control>
{
    formik.errors.id_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_estrazione</label>
<Form.Control type="text" name="data_estrazione" className="form-control" value={formik.values.data_estrazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_estrazione && !!formik.errors.data_estrazione}
isValid ={!!formik.touched.data_estrazione && !formik.errors.data_estrazione}
></Form.Control>
{
    formik.errors.data_estrazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_estrazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_anagrafiche</label>
<Form.Control type="text" name="numero_anagrafiche" className="form-control" value={formik.values.numero_anagrafiche}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_anagrafiche && !!formik.errors.numero_anagrafiche}
isValid ={!!formik.touched.numero_anagrafiche && !formik.errors.numero_anagrafiche}
></Form.Control>
{
    formik.errors.numero_anagrafiche && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_anagrafiche}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nome_file</label>
<Form.Control type="text" name="nome_file" className="form-control" value={formik.values.nome_file}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_file && !!formik.errors.nome_file}
isValid ={!!formik.touched.nome_file && !formik.errors.nome_file}
></Form.Control>
{
    formik.errors.nome_file && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_file}
    </Form.Control.Feedback>
)}
</Form.Group> */}
<Form.Group>
<label className="form -control-label">Nome Ricerca</label>
<Form.Control type="text" name="nome_ricerca" className="form-control" value={formik.values.nome_ricerca}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_ricerca && !!formik.errors.nome_ricerca}
isValid ={!!formik.touched.nome_ricerca && !formik.errors.nome_ricerca}
></Form.Control>
{
    formik.errors.nome_ricerca && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_ricerca}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-left" variant="primary">Salva Modifica</Button>
                        
                        <Button variant="secondary" className="float-right" onClick={() => hideShowForm(false)}>
                            <span className="text">Annulla</span>
                        </Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

