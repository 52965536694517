import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setOrdiniMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addOrdini, updateOrdini } from "services/ordiniService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const OrdiniForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',data:'',metodo_di_pagamento:'',prezzo_totale:'',stato_ordine:'',id_abbonamento:'',id_utente:'',id_login:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateOrdini(values);
                if (response) {
                    dispatch(setOrdiniMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOrdiniMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addOrdini(values);
                if (response) {
                    dispatch(setOrdiniMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setOrdiniMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           data: yup.date().nullable(),
metodo_di_pagamento: yup.string().nullable(),
prezzo_totale: yup.number().nullable(),
stato_ordine: yup.number().nullable(),
id_abbonamento: yup.number().nullable(),
id_utente: yup.number().nullable(),
id_login: yup.number().nullable(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Ordini
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Ordini</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">data</label>
<Form.Control type="text" name="data" className="form-control" value={formik.values.data}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data && !!formik.errors.data}
isValid ={!!formik.touched.data && !formik.errors.data}
></Form.Control>
{
    formik.errors.data && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">metodo_di_pagamento</label>
<Form.Control type="text" name="metodo_di_pagamento" className="form-control" value={formik.values.metodo_di_pagamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.metodo_di_pagamento && !!formik.errors.metodo_di_pagamento}
isValid ={!!formik.touched.metodo_di_pagamento && !formik.errors.metodo_di_pagamento}
></Form.Control>
{
    formik.errors.metodo_di_pagamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.metodo_di_pagamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">prezzo_totale</label>
<Form.Control type="text" name="prezzo_totale" className="form-control" value={formik.values.prezzo_totale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.prezzo_totale && !!formik.errors.prezzo_totale}
isValid ={!!formik.touched.prezzo_totale && !formik.errors.prezzo_totale}
></Form.Control>
{
    formik.errors.prezzo_totale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.prezzo_totale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">stato_ordine</label>
<Form.Control type="text" name="stato_ordine" className="form-control" value={formik.values.stato_ordine}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.stato_ordine && !!formik.errors.stato_ordine}
isValid ={!!formik.touched.stato_ordine && !formik.errors.stato_ordine}
></Form.Control>
{
    formik.errors.stato_ordine && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.stato_ordine}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_abbonamento</label>
<Form.Control type="text" name="id_abbonamento" className="form-control" value={formik.values.id_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_abbonamento && !!formik.errors.id_abbonamento}
isValid ={!!formik.touched.id_abbonamento && !formik.errors.id_abbonamento}
></Form.Control>
{
    formik.errors.id_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_utente</label>
<Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
></Form.Control>
{
    formik.errors.id_utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_login</label>
<Form.Control type="text" name="id_login" className="form-control" value={formik.values.id_login}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_login && !!formik.errors.id_login}
isValid ={!!formik.touched.id_login && !formik.errors.id_login}
></Form.Control>
{
    formik.errors.id_login && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_login}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

