import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPiano_Estrazioni {
id:number,
id_cliente:number,
id_abbonamento:number,
data_estrazione:Date,
numero_anagrafiche:number,
nome_file:string,
nome_ricerca:string,
dettaglio: string,
dati_ricerca:string,

}

interface IPiano_EstrazioniData {
    list?: Array<IPiano_Estrazioni>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPiano_EstrazioniData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const piano_estrazioniSlice = createSlice({
    name: "piano_estrazioni",
    initialState,
    reducers: {
        setPiano_EstrazioniList: (state, _action: PayloadAction<IPiano_EstrazioniData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPiano_EstrazioniToInit: (state) => {
            state = initialState;
        },
        setPiano_EstrazioniMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPiano_EstrazioniList, resetPiano_EstrazioniToInit, setPiano_EstrazioniMessage } = piano_estrazioniSlice.actions;

export default piano_estrazioniSlice.reducer;

