import { APIService } from "services";


export const getRicercaElencoImpresaPreventivo= (dati_ricerca) => {
    return APIService.api().post(`/ws_elenco_imprese/richiesta_preventivo.php`, dati_ricerca);
}

export const getRichiestaElencoImpresa= (dati_ricerca) => {
    return APIService.api().post(`/ws_elenco_imprese/richiesta_elenco.php`, dati_ricerca);
}

export const getRicercaElencoImpresaPreventivoDiretto= (dati_ricerca) => {
    return APIService.api().post(`/ws_elenco_imprese/richiesta_preventivo_diretto.php`, dati_ricerca);
}