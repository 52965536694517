import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IElenco_Imprese_Esteso {
progressivo:string,
provincia_nrea:string,
n_rea:string,
codice_fiscale:string,
partita_iva:string,
tipo_sede:string,
progressivo_localizzazione:string,
n_albo_artigiani:string,
cod_sezione_registro:string,
sezione_registro:string,
cod_forma_giuridica:string,
forma_giuridica:string,
sito_internet:string,
email:string,
data_iscrizione:string,
data_cancellazione:string,
data_ultimo_protocollo:string,
denominazione:string,
codice_toponimo:string,
toponimo:string,
indirizzo:string,
numero_civico:string,
codice_stradario:string,
cap:string,
codice_comune_catastale:string,
codice_comune_camerale:string,
comune:string,
sigla_provincia:string,
provincia_istat:string,
sigla_provincia_camerale:string,
frazione:string,
altre_indicazioni_indirizzo:string,
codice_stato_estero:string,
stato_estero:string,
anno_dichiarazione_addetti:string,
addetti_indipendenti:string,
addetti_dipendenti:string,
telefono:string,
capitale_sociale:string,
valuta:string,
attivita_dichiarata:string,
codice_ateco_importanza:string,
codice_stato_attivita:string,
stato_attivita:string,
data_ultimo_bilancio_xbrl:string,
valore_produzione:string,
ricavi:string,
utile_perdite:string,
opzionale:string
}

interface IElenco_Imprese_EstesoData {
    list?: Array<IElenco_Imprese_Esteso>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IElenco_Imprese_EstesoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const elenco_imprese_estesoSlice = createSlice({
    name: "elenco_imprese_esteso",
    initialState,
    reducers: {
        setElenco_Imprese_EstesoList: (state, _action: PayloadAction<IElenco_Imprese_EstesoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetElenco_Imprese_EstesoToInit: (state) => {
            state = initialState;
        },
        setElenco_Imprese_EstesoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setElenco_Imprese_EstesoList, resetElenco_Imprese_EstesoToInit, setElenco_Imprese_EstesoMessage } = elenco_imprese_estesoSlice.actions;

export default elenco_imprese_estesoSlice.reducer;

