import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWs_Login {
id:number,
username:string,
password:string,
email?:string,
telefono:string,
nome_referente:string,
cognome_referente:string,
nome_azienda?:string,
partita_iva?:string
}

interface IWs_LoginData {
    list?: Array<IWs_Login>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWs_LoginData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ws_loginSlice = createSlice({
    name: "ws_login",
    initialState,
    reducers: {
        setWs_LoginList: (state, _action: PayloadAction<IWs_LoginData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWs_LoginToInit: (state) => {
            state = initialState;
        },
        setWs_LoginMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWs_LoginList, resetWs_LoginToInit, setWs_LoginMessage } = ws_loginSlice.actions;

export default ws_loginSlice.reducer;

