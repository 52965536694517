import axios from "axios"
export class APIService {

    static apiCustomHeader(headers: any) {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: headers,
        })
    }
    static api() {
        const jwtToken = localStorage.getItem("token");
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + jwtToken
            }
        })
    }

    static api_documento() {
        const jwtToken = localStorage.getItem("token");
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/octet-stream',
                'Authorization': 'Bearer ' + jwtToken
            }
        })
    }

    
    static apiNoAuth() {
        return axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            }
        })
    }
    

    static apiPay(){
        const jwtToken = localStorage.getItem("token");
        return axios.create({
            //baseURL: "https://servizi.tecnoinnovis.it/mypay-0.0.1-SNAPSHOT/api",//process.env.REACT_APP_API_PAY_BASE_URL,
            baseURL: process.env.REACT_APP_API_PAY_BASE_URL, //'http://localhost:8080/api',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                //'Authorization': 'Bearer ' + jwtToken
            }
        })
    }
}
