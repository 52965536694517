import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetElenco_Imprese_EstesoToInit, resetElenco_Imprese_Esteso_GraficiToInit, setElenco_Imprese_EstesoMessage, setElenco_Imprese_Esteso_GraficiMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteElenco_Imprese_Esteso } from "services/elenco_imprese_estesoService";
import LineChart from "components/LineChart";
import { UserData } from "Data";
import { elements } from "chart.js";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
    getData_bilancio: (page, pageSize, searchKey, data) => void;
};
export const Elenco_Imprese_GraficiTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData,  getData_bilancio}) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [data, setData] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.elenco_imprese_esteso_grafici);
    const handleSearch = (search) => {
        setSearch(search);
        if (search.length > 0) {
            getData_bilancio(Constant.defaultPageNumber, Constant.defaultPageSize, search, data);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData_bilancio(Constant.defaultPageNumber, Constant.defaultPageSize, '', '31/12/2021');
    }
    const handlePageChange = (page) => {
        getData_bilancio(Constant.defaultPageNumber, Constant.defaultPageSize, '', '31/12/2021');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetElenco_Imprese_Esteso_GraficiToInit());
            getData_bilancio(Constant.defaultPageNumber, Constant.defaultPageSize, '', '31/12/2021');
        }
    })
    const handleReset = () => {
        setSearch('');
        dispatch(resetElenco_Imprese_Esteso_GraficiToInit());
        getData_bilancio(Constant.defaultPageNumber, rData.pageSize, '', '31/12/2021');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteElenco_Imprese_Esteso(rowData.progressivo);
            if (response) {
                dispatch(resetElenco_Imprese_Esteso_GraficiToInit());
                dispatch(setElenco_Imprese_Esteso_GraficiMessage("Deleted Successfully"));
                getData_bilancio(Constant.defaultPageNumber, Constant.defaultPageSize, '', '31/12/2021');
                setShowDelete(false);
            } else {
                dispatch(setElenco_Imprese_Esteso_GraficiMessage("Some error occured!"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setElenco_Imprese_Esteso_GraficiMessage(''));
        hideShowForm('add');
    }

    const capSoc = (rData) => {
        if(rData.length > 0){
            let ar = [];
            rData.forEach(element => {
                ar.push(1000000);
            });
                
            console.log(ar)
            return ar;
        }        
    }

    const conversione_dato = (dato_string) => {
        let dato_string_tmp = dato_string.replace(".","")
        dato_string_tmp = dato_string_tmp.replace(".","")
        dato_string_tmp = dato_string_tmp.replace(",00","")
        dato_string_tmp = dato_string_tmp.replace(",00","")
        dato_string = dato_string_tmp;
        console.log(dato_string)
        return dato_string;
    }

    
    const [dataBilancio, setDataBilancio] = useState({
        labels: rData.list.map((data) => data.provincia_nrea),
        datasets: [
          {
            fill: true,
            label: "Media Utile Perdite",
            data: rData.list.map((data) => conversione_dato(data.media_utile_perdite)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            fill: true,
            label: "Capitale Totale",
            data: rData.list.map((data) => conversione_dato(data.media_valore_produzione)),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });


      const [dataAddetti, setDataAddetti] = useState({
        labels: rData.list.map((dataArray) => dataArray.provincia_nrea),
        datasets: [
          {
            fill: true,
            label: "Addetti Totali",
            data: rData.list.map((dataArray) => conversione_dato(dataArray.addetti_totali)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            fill: true,
            label: "Media Addetti",
            data: rData.list.map((dataArray) => conversione_dato(dataArray.media_addetti)),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });

      const getDataEstrazione = (dataArray) =>{
        if(dataArray != null && dataArray.length>0){
            console.log(dataArray)
            const data_estrazione_dati = dataArray[0].data_estrazione
            return data_estrazione_dati;
        }else{
            return "calcolo in corso..."
        }
        
      }
      const dataEstrazione = getDataEstrazione(rData.list)

      const addetti = {
        labels: rData.list.map((dataArray) => dataArray.provincia_nrea),
        datasets: [
          {
            fill: true,
            label: "Addetti Totali",
            data: rData.list.map((dataArray) => conversione_dato(dataArray.addetti_totali)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            fill: true,
            label: "Media Addetti",
            data: rData.list.map((dataArray) => conversione_dato(dataArray.media_addetti)),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };

      const bilancio = {
        labels: rData.list.map((data) => data.provincia_nrea),
        datasets: [
          {
            fill: true,
            label: "Media Utile Perdite",
            data: rData.list.map((data) => conversione_dato(data.media_utile_perdite)),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
          },
          {
            fill: true,
            label: "Capitale Totale",
            data: rData.list.map((data) => conversione_dato(data.media_valore_produzione)),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      };


      
    return (
        <Row>
            <div className="col-sm-12">
                <p>Dati estrazione del: {dataEstrazione}</p>
            </div>
            <div className="col-sm-6">
                <LineChart chartData={bilancio} />
            </div>
            <div className="col-sm-6">
                <LineChart chartData={addetti} />
            </div>
        </Row>
    );
}

