import { APIService } from "services";

export const getWs_Login = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Login(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Login(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


// export const getLoginByUsername = (id) => {
// return APIService.api().get(`/ws_login/read_by_username.php?rif=${id}`)
// }

export const getAllWs_Login = (pageno,pagesize) => {
return APIService.api().get(`/ws_login/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Login = (id) => {
return APIService.api().get(`/ws_login/read_one.php?id=${id}`)
}
export const searchWs_Login = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_login/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Login = (data) => {
return APIService.api().post(`/ws_login/create.php`,data)
}
export const registration = (data) => {
    return APIService.apiNoAuth().post(`/ws_login/registration.php`,data)
    }
export const updateWs_Login = (data) => {
return APIService.api().post(`/ws_login/update.php`,data)
}
export const deleteWs_Login = (id) => {
return APIService.api().post(`/ws_login/delete.php`,{id:id})
}
