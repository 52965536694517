import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { array } from 'yup';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const DetailOrdine: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    if(body != null){
        console.log(body)
        let pacchetti = [];
        pacchetti = body["lista"];
        let idordine = body["id"]
        let costo = 0
        let costoIva = 0
        let costoNoIva = 0;
            return (
                <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-confirm'><i className="fa fa-check-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className='text-alert-center-bold'>Dettaglio Ordine n. {idordine}</p>
                                    <p className='text-alert-center-bold'>Ecco cosa hai scelto:</p>
                                    
                                    {pacchetti.map((pacchetto, i) =>{
                                        costo = costo + pacchetto.costo
                                        costoIva = costo * 1.22
                                        costoNoIva = costoIva - costo
                                        return(
                                            <div className='row'>
                                                <div className='col-sm-2'></div>
                                                <div className='col-sm-6 text-confirm-ordine'>{pacchetto.nomeAbbonamento}</div>
                                                <div className='col-sm-2 text-confirm-ordine'>€ {Number(pacchetto.costo).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                        )
                                    })}
                                    <div className='row'>
                                        <div className='col-sm-2'></div>
                                        <div className='col-sm-6 text-confirm-ordine'>Iva:</div>
                                        <div className='col-sm-2 text-confirm-ordine'>€ {Number(costoNoIva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</div>
                                        <div className='col-sm-2'></div>
                                    </div> 

                                    <div className='row'>
                                        <div className='col-sm-2'></div>
                                        <div className='col-sm-6 text-confirm-ordine color-green'>Totale Iva Inclusa:</div>
                                        <div className='col-sm-2 text-confirm-ordine color-green'>€ {Number(costoIva).toLocaleString('it-IT', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                                })}</div>
                                        <div className='col-sm-2'></div>
                                    </div>  
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                            {buttonNegative}
                        </Button>
                    </Modal.Footer>
                </Modal>
            );    
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold'>Abbiamo rilevato un errore nella compilazione del tuo ordine! Riprova</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default DetailOrdine;

