import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setWs_LoginMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addWs_Login, updateWs_Login } from "services/ws_loginService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { parseJwtToken } from "services/tokenService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Ws_LoginForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    const iValue={id:'',username:'',password:'',email:'',telefono:'',nome_referente:'',cognome_referente:'',nome_azienda:'',partita_iva:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateWs_Login(values);
                if (response) {
                    dispatch(setWs_LoginMessage("Modifica profilo avvenuta con successo"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_LoginMessage("Modifica profilo non avvenuta con successo, rilevato errore riprovare"));
                }
            } else if (action === 'add') {
                const response = await addWs_Login(values);
                if (response) {
                    dispatch(setWs_LoginMessage("Aggiunta profilo avvenuta con successo"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_LoginMessage("Aggiunta profilo non avvenuta con successo, rilevato errore riprovare"));
                }
            }
        },
        validationSchema: yup.object({
           //username: yup.string().required('username is required'),
//password: yup.string().required('password is required'),
email: yup.string().nullable(),
telefono: yup.string().required('telefono is required'),
nome_referente: yup.string().required('nome_referente is required'),
cognome_referente: yup.string().required('cognome_referente is required'),
nome_azienda: yup.string().nullable(),
partita_iva: yup.string().nullable(),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <h4 className="">Dati Profilo
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Dati_Utente</span>
                    </Button> */}
                    <Button variant="info" className="float-right"  onClick={() => hideShowForm(false)}><i className="fa-solid fa-long-arrow-left"></i> Lista</Button>
                </h4>


            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
                    {jwtToken == null && jwtToken.data.id == 1 && 
                    <><label className="form -control-label">username</label>
                    <Form.Control type="text" name="username" className="form-control" value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur ={formik.handleBlur}
                    isInvalid ={!!formik.touched.username && !!formik.errors.username}
                    isValid ={!!formik.touched.username && !formik.errors.username}
                    ></Form.Control>
                    {
                        formik.errors.username && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.username}
                        </Form.Control.Feedback>
                    )}
                    
                    </>
}

</Form.Group>
<Form.Group>
{jwtToken != null && jwtToken.data.id == 1 && 
<>
<label className="form -control-label">password</label>
<Form.Control type="text" name="password" className="form-control" value={formik.values.password}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password && !!formik.errors.password}
isValid ={!!formik.touched.password && !formik.errors.password}
></Form.Control>
{
    formik.errors.password && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password}
    </Form.Control.Feedback>
)}
</>
}

</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" disabled={jwtToken != null && jwtToken.data.id != 1} value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nome_referente</label>
<Form.Control type="text" name="nome_referente" className="form-control" value={formik.values.nome_referente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_referente && !!formik.errors.nome_referente}
isValid ={!!formik.touched.nome_referente && !formik.errors.nome_referente}
></Form.Control>
{
    formik.errors.nome_referente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_referente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cognome_referente</label>
<Form.Control type="text" name="cognome_referente" className="form-control" value={formik.values.cognome_referente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cognome_referente && !!formik.errors.cognome_referente}
isValid ={!!formik.touched.cognome_referente && !formik.errors.cognome_referente}
></Form.Control>
{
    formik.errors.cognome_referente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cognome_referente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nome_azienda</label>
<Form.Control type="text" name="nome_azienda" className="form-control" value={formik.values.nome_azienda}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_azienda && !!formik.errors.nome_azienda}
isValid ={!!formik.touched.nome_azienda && !formik.errors.nome_azienda}
></Form.Control>
{
    formik.errors.nome_azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_azienda}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">partita_iva</label>
<Form.Control type="text" name="partita_iva" className="form-control" value={formik.values.partita_iva}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.partita_iva && !!formik.errors.partita_iva}
isValid ={!!formik.touched.partita_iva && !formik.errors.partita_iva}
></Form.Control>
{
    formik.errors.partita_iva && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.partita_iva}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                    <Button type="submit" className="float-left" variant="success">Conferma</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

