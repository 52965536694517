import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Nav } from "react-bootstrap";
import { useSelector } from "react-redux";
import { activateTopMenu, sidebarToggle } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resolveHookState } from "react-use/lib/util/resolveHookState";
import { getAllArchivioDaleggere } from "services/archivioService";
import { replace } from "formik";
const Topbar: React.FC = () => {
    const token = localStorage.getItem('token');
    let nome_azienda = "";

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleActiveDropdown = (menuName: string) => {
        dispatch(activateTopMenu(menuName))
    }

    const[docDaLeggere, setDocDaLeggere] = useState();
    const isTopActiveMenu = useSelector((state: RootState) => state.template.topActiveMenu);
    const userProfile = useSelector((state: RootState) => state.template.userProfile);
    const handleLogout = () => {
        localStorage.setItem('token', 'vuoto')
        localStorage.clear();
        // resetReduce((state: RootState) => state.piano_abbonamento = null, 'reset')
        // resetReduce((state: RootState) => state.piano_estrazioni = null, 'reset')
        navigate('/', { replace: true});
        navigate(0);
    }

    const handleLinkAddebiti = () => {
        navigate('/ws_addebiti')
    }

    const handleLinkCambioPassword = () => {
        navigate('/cambio_password', { replace: true })
    }

    const handleProfilo = () => {
        navigate('/profilo', { replace: true })
    }


    function parseJwt(token) {
        try {
          // Get Token Header
          const base64HeaderUrl = token.split('.')[0];
          const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
          const headerData = JSON.parse(window.atob(base64Header));
      
          // Get Token payload and date's
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace('-', '+').replace('_', '/');
          const dataJWT = JSON.parse(window.atob(base64));
          dataJWT.header = headerData;
      
          // TODO: add expiration at check ...
          return dataJWT;
        } catch (err) {
          return false;
        }
      }
      
      const jwtDecoded = parseJwt(token) ;
      if(jwtDecoded)
      {
          //console.log(jwtDecoded.data.id)
          nome_azienda = jwtDecoded.data.nome_azienda;

      }

    useEffect(() => {

        getAllArchivioDaleggere().then(response => {
            //console.log(response) 
            setDocDaLeggere(response.data.document.total_count)
         })
         .catch(error => {
             console.log(error) 
         });
        
    }, [docDaLeggere])

    const handleArchivio = () =>{
        navigate('/archivio', { replace: true });
    }


    return (
        <Nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

            {/* <span>Benvenuto: {nome_azienda}</span> */}
            <button id="sidebarToggleTop" className="btn btn-link text-dark d-md-none rounded-circle mr-3" onClick={() => dispatch(sidebarToggle())}>
                <i className="fa fa-bars"></i>
            </button>
            <ul className="navbar-nav ml-auto">
                <li className={isTopActiveMenu === "Notification" ? "nav-item dropdown no-arrow mx-1 show" : "nav-item dropdown no-arrow mx-1"} >

                    <Nav.Link className="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded={isTopActiveMenu === "Notification" ? true : false} onClick={() => handleActiveDropdown("Notification")}>
                        <i className="fas fa-bell fa-fw"></i>

                        <span className="badge badge-danger badge-counter">{docDaLeggere}</span>
                    </Nav.Link>

                    <div className={isTopActiveMenu === "Notification" ? "dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in show" : "dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"}
                        aria-labelledby="alertsDropdown">
                        <h6 className="dropdown-header">
                            Aggiornamenti
                        </h6>
                        <a className="dropdown-item d-flex align-items-center" onClick={handleArchivio}>
                            <div className="mr-3">
                                <div className="icon-circle bg-success">
                                    <i className="fas fa-file-alt text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">Sezione Archivio</div>
                                <span className="font-weight-bold">Hai {docDaLeggere} documenti da consultare</span>
                            </div>
                        </a>
                        {/* <a className="dropdown-item d-flex align-items-center">
                            <div className="mr-3">
                                <div className="icon-circle bg-success">
                                    <i className="fas fa-donate text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 7, 2019</div>
                                $290.29 has been deposited into your account!
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center">
                            <div className="mr-3">
                                <div className="icon-circle bg-warning">
                                    <i className="fas fa-exclamation-triangle text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 2, 2019</div>
                                Spending Alert: We've noticed unusually high spending for your account.
                            </div>
                        </a>
                        <a className="dropdown-item text-center small text-gray-500"></a> */}
                    </div>
                </li>


                <div className="topbar-divider d-none d-sm-block"></div>
                <li className={isTopActiveMenu === "Profile" ? "nav-item dropdown no-arrow show" : "nav-item dropdown no-arrow"} onClick={() => handleActiveDropdown("Profile")}>
                    <a className="nav-link dropdown-toggle" id="userDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">{userProfile? Object.values(userProfile)[1] : 'NA'}</span>
                        <i className="fas fa-circle-user icon-profilo"></i>
                    </a>
                    <div className={isTopActiveMenu === "Profile" ? "dropdown-menu dropdown-menu-right shadow animated--grow-in show" : "dropdown-menu dropdown-menu-right shadow animated--grow-in"}
                        aria-labelledby="userDropdown">
                        <p className="dropdown-item btn"><strong>{nome_azienda}</strong></p>
                        <Button variant="link" className="dropdown-item" onClick={handleProfilo}>
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            {/* {userProfile? Object.values(userProfile)[1]: 'NA'} */}
                            Profilo
                        </Button>
                        <Button variant="link" className="dropdown-item" onClick={handleLinkCambioPassword}>
                            <i className="fas fa-unlock fa-sm fa-fw mr-2 text-gray-400"></i>
                            {/* {userProfile? Object.values(userProfile)[1]: 'NA'} */}
                            Cambio Password
                        </Button>
                        <Button variant="link" className="dropdown-item" onClick={handleLinkAddebiti}>
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Addebiti
                        </Button>
                        {/* <Button variant="link" className="dropdown-item">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </Button> */}
                        <div className="dropdown-divider"></div>
                        <Button variant="link" className="dropdown-item" onClick={handleLogout}>
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </Button>
                    </div>
                </li>

            </ul>

        </Nav>
    );
};

export default Topbar;

