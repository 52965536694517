import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IComuni_Catastali {
codicecomune:string,
nomecomune:string,
provincia:string
}

interface IComuni_CatastaliData {
    list?: Array<IComuni_Catastali>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IComuni_CatastaliData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const comuni_catastaliSlice = createSlice({
    name: "comuni_catastali",
    initialState,
    reducers: {
        setComuni_CatastaliList: (state, _action: PayloadAction<IComuni_CatastaliData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetComuni_CatastaliToInit: (state) => {
            state = initialState;
        },
        setComuni_CatastaliMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setComuni_CatastaliList, resetComuni_CatastaliToInit, setComuni_CatastaliMessage } = comuni_catastaliSlice.actions;

export default comuni_catastaliSlice.reducer;

