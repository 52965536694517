import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IArea_geografica {
id_utente:number,
id_abbonamento:number,
codice_abbonamento:string,
numero_anagrafiche_massimo:number,
numero_anagrafiche_residuo:number,
data_apertura:Date,
data_scadenza:Date,
attivo:number
}

interface IArea_GeograficaData {
    list?: Array<any>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IArea_GeograficaData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const areaGeograficaSlice = createSlice({
    name: "area_geografica",
    initialState,
    reducers: {
        setAreaGeograficaList: (state, _action: PayloadAction<IArea_GeograficaData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetAreaGeograficaToInit: (state) => {
            state = initialState;
        },
        setAreaGeograficaMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setAreaGeograficaList, resetAreaGeograficaToInit, setAreaGeograficaMessage } = areaGeograficaSlice.actions;

export default areaGeograficaSlice.reducer;

