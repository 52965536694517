import React, { FC, ReactNode, useState } from 'react';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import { acquistaListaRicerca, salvaListaRicerca } from 'services/lista_ricerche_service';
import { salvaRicerca } from 'services/ricercaUnicaService';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};




const ConfirmationAcquistaRicerca: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    if(body != null){
    return (
        <>
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className='icon-confirm'><i className="fa fa-check-circle"></i></p>
                        </div>
                        <div className='col-sm-12'>
                            <p className='text-alert-center-bold'>Acquisto effettuato con successo. Il file contenente la lista sarà disponibile a breve nella sezione "Acquistate"</p>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className='col-sm-12'>
                            <h5>Stai per acquistare il dettaglio di {body["anagrafiche"]} aziende che verranno scalate dal tuo abbonamento</h5>
                        </div>
                    </div> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold'>Abbiamo rilevato un errore nel rilascio del preventivo</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default ConfirmationAcquistaRicerca;

