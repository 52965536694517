import { APIService } from "services";
import { parseJwtToken } from "./tokenService";

export const getWs_Addebiti = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Addebiti(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Addebiti(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getWs_Addebiti_User = async (pageNo,pageSize,search, idUser) => {
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    if(jwtToken)
    {
        idUser = jwtToken.data.id;
    }else{
        idUser = 0; 
    }
    let res;
    if(search.length===0) {
        res = await getWs_AddebitiUtenti(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Addebiti(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllWs_Addebiti = (pageno,pagesize) => {
return APIService.api().get(`/ws_addebiti/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Addebiti = (id) => {
return APIService.api().get(`/ws_addebiti/read_one.php?id=${id}`)
}
export const searchWs_Addebiti = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_addebiti/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Addebiti = (data) => {
return APIService.api().post(`/ws_addebiti/create.php`,data)
}
export const updateWs_Addebiti = (data) => {
return APIService.api().post(`/ws_addebiti/update.php`,data)
}
export const deleteWs_Addebiti = (id) => {
return APIService.api().post(`/ws_addebiti/delete.php`,{id:id})
}
export const getWs_AddebitiUtenti = (pageno,pagesize) => {
return APIService.api().get(`/ws_addebiti/read_by_utente.php?pageno=${pageno}&pagesize=${pagesize}`)
}
