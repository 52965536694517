import { APIService } from "services";

export const getWs_Log_Richieste = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Log_Richieste(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Log_Richieste(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllWs_Log_Richieste = (pageno,pagesize) => {
return APIService.api().get(`/ws_log_richieste/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Log_Richieste = (id) => {
return APIService.api().get(`/ws_log_richieste/read_one.php?id=${id}`)
}
export const searchWs_Log_Richieste = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_log_richieste/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Log_Richieste = (data) => {
return APIService.api().post(`/ws_log_richieste/create.php`,data)
}
export const updateWs_Log_Richieste = (data) => {
return APIService.api().post(`/ws_log_richieste/update.php`,data)
}
export const deleteWs_Log_Richieste = (id) => {
return APIService.api().post(`/ws_log_richieste/delete.php`,{id:id})
}
