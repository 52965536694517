import { APIService } from "services";

export const getWs_Prodotti = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Prodotti(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Prodotti(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllWs_Prodotti = (pageno,pagesize) => {
return APIService.api().get(`/ws_prodotti/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Prodotti = (id) => {
return APIService.api().get(`/ws_prodotti/read_one.php?id=${id}`)
}
export const searchWs_Prodotti = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_prodotti/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Prodotti = (data) => {
return APIService.api().post(`/ws_prodotti/create.php`,data)
}
export const updateWs_Prodotti = (data) => {
return APIService.api().post(`/ws_prodotti/update.php`,data)
}
export const deleteWs_Prodotti = (id) => {
return APIService.api().post(`/ws_prodotti/delete.php`,{id:id})
}
