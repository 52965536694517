import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITipologie_Abbonamento {
codice_abbonamento:string,
nome_abbonamento:string,
durata:number,
costo:number,
ricercaunica:number,
anagrafica:number,
dettaglio:number,
visure:number,
bilanci:number,
protesti:number,
catastali:number,
reportsmart:number,
deepdossier:number,
executivereport:number,
reportpersona:number,
reportpersonacompleto:number
}

interface ITipologie_AbbonamentoData {
    list?: Array<ITipologie_Abbonamento>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITipologie_AbbonamentoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const tipologie_abbonamentoSlice = createSlice({
    name: "tipologie_abbonamento",
    initialState,
    reducers: {
        setTipologie_AbbonamentoList: (state, _action: PayloadAction<ITipologie_AbbonamentoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTipologie_AbbonamentoToInit: (state) => {
            state = initialState;
        },
        setTipologie_AbbonamentoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTipologie_AbbonamentoList, resetTipologie_AbbonamentoToInit, setTipologie_AbbonamentoMessage } = tipologie_abbonamentoSlice.actions;

export default tipologie_abbonamentoSlice.reducer;

