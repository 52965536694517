import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ILogin {
id:number,
username:string,
password:string,
tipo_login?:number,
id_registrante?:number,
tipo_registrante?:string,
cognome?: string,
nome?: string
}

interface ILoginData {
    list?: Array<ILogin>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ILoginData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setLoginList: (state, _action: PayloadAction<ILoginData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetLoginToInit: (state) => {
            state = initialState;
        },
        setLoginMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setLoginList, resetLoginToInit, setLoginMessage } = loginSlice.actions;

export default loginSlice.reducer;

