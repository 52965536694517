import React , { Component} from 'react';
import * as d3 from 'd3';

const colors = [ '#57e188', '#ff9831',  '#f95d54' ];


class DonutChartSemi extends Component {

    constructor(props) {
        super(props);
        this.chRef = React.createRef();
    }


    // Chart load after component Mount
    componentDidMount() {
        this.drawChart()
    }


    // DrawChart 
    drawChart(){
        var test = this.props.idgrafico
        var score = this.props.score
        var vuoto = this.props.vuoto
        var color = d3.scaleOrdinal(this.props.colori);
        // Dati di esempio
        const data = [
            { label: 'Categoria 1', value: 33.33 },
            { label: 'Categoria 2', value: 33.33 },
            { label: 'Categoria 3', value: 33.33 }
          ];
          
          // Dimensioni del grafico
          const width = 250;
          const height = 250;
          const radius = Math.min(width, height) / 2;
          
          // Seleziona il contenitore del grafico
          const svg = d3.select('#' + test)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2},${height / 2}) rotate(-90)`); // Ruota di -90 gradi
          
          // Angoli fissi in modo da formare un semicerchio
          const angles = [
            { startAngle: 0, endAngle: Math.PI / 3 },        // Primo segmento
            { startAngle: Math.PI / 3, endAngle: 2 * Math.PI / 3 },  // Secondo segmento
            { startAngle: 2 * Math.PI / 3, endAngle: Math.PI }      // Terzo segmento
          ];
          
          // Creazione degli archi
          const arc = d3.arc()
            .innerRadius(radius * 0.6)
            .outerRadius(radius);
          
          // Aggiunta dei segmenti al grafico
          svg.selectAll('path')
            .data(angles)
            .enter()
            .append('path')
            .attr('d', d => arc(d))
            .attr('fill', (d, i) => color(i)); // Colori delle categorie
            
          
        //   // Aggiunta delle etichette
        //   svg.selectAll('text')
        //     .data(data)
        //     .enter()
        //     .append('text')
        //     .attr('transform', (d, i) => {
        //       const angle = (angles[i].startAngle + angles[i].endAngle) / 2;
        //       const x = Math.cos(angle) * (radius * 0.8);
        //       const y = Math.sin(angle) * (radius * 0.8);
        //       return `translate(${x},${y})`;
        //     })
        //     .attr('dy', '0.35em')
        //     .attr('text-anchor', 'middle')
        //     .text(d => d.label);
            
    }



    render() {
        return <>
            <div id={this.props.idgrafico} className='posizione-grafico altezza-150 center-block-custom' ></div>
            {/* <div ref={this.chRef}></div> */}
             </>
    }


}

export default DonutChartSemi;