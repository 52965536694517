import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setTipologie_AbbonamentoMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addTipologie_Abbonamento, updateTipologie_Abbonamento } from "services/tipologie_abbonamentoService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Tipologie_AbbonamentoForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={codice_abbonamento:'',nome_abbonamento:'',durata:'',costo:'',ricercaunica:'',anagrafica:'',dettaglio:'',visure:'',bilanci:'',protesti:'',catastali:'',reportsmart:'',deepdossier:'',executivereport:'',reportpersona:'',reportpersonacompleto:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateTipologie_Abbonamento(values);
                if (response) {
                    dispatch(setTipologie_AbbonamentoMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTipologie_AbbonamentoMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addTipologie_Abbonamento(values);
                if (response) {
                    dispatch(setTipologie_AbbonamentoMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setTipologie_AbbonamentoMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           codice_abbonamento: yup.string().required('codice_abbonamento is required'),
nome_abbonamento: yup.string().required('nome_abbonamento is required'),
durata: yup.number().required('durata is required'),
costo: yup.number().required('costo is required'),
ricercaunica: yup.number().required('ricercaunica is required'),
anagrafica: yup.number().required('anagrafica is required'),
dettaglio: yup.number().required('dettaglio is required'),
visure: yup.number().required('visure is required'),
bilanci: yup.number().required('bilanci is required'),
protesti: yup.number().required('protesti is required'),
catastali: yup.number().required('catastali is required'),
reportsmart: yup.number().required('reportsmart is required'),
deepdossier: yup.number().required('deepdossier is required'),
executivereport: yup.number().required('executivereport is required'),
reportpersona: yup.number().required('reportpersona is required'),
reportpersonacompleto: yup.number().required('reportpersonacompleto is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Tipologie_Abbonamento
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Tipologie_Abbonamento</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">codice_abbonamento</label>
<Form.Control type="text" name="codice_abbonamento" className="form-control" value={formik.values.codice_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_abbonamento && !!formik.errors.codice_abbonamento}
isValid ={!!formik.touched.codice_abbonamento && !formik.errors.codice_abbonamento}
></Form.Control>
{
    formik.errors.codice_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nome_abbonamento</label>
<Form.Control type="text" name="nome_abbonamento" className="form-control" value={formik.values.nome_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_abbonamento && !!formik.errors.nome_abbonamento}
isValid ={!!formik.touched.nome_abbonamento && !formik.errors.nome_abbonamento}
></Form.Control>
{
    formik.errors.nome_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">durata</label>
<Form.Control type="text" name="durata" className="form-control" value={formik.values.durata}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.durata && !!formik.errors.durata}
isValid ={!!formik.touched.durata && !formik.errors.durata}
></Form.Control>
{
    formik.errors.durata && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.durata}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">costo</label>
<Form.Control type="text" name="costo" className="form-control" value={formik.values.costo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.costo && !!formik.errors.costo}
isValid ={!!formik.touched.costo && !formik.errors.costo}
></Form.Control>
{
    formik.errors.costo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.costo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">ricercaunica</label>
<Form.Control type="text" name="ricercaunica" className="form-control" value={formik.values.ricercaunica}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ricercaunica && !!formik.errors.ricercaunica}
isValid ={!!formik.touched.ricercaunica && !formik.errors.ricercaunica}
></Form.Control>
{
    formik.errors.ricercaunica && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ricercaunica}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">anagrafica</label>
<Form.Control type="text" name="anagrafica" className="form-control" value={formik.values.anagrafica}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.anagrafica && !!formik.errors.anagrafica}
isValid ={!!formik.touched.anagrafica && !formik.errors.anagrafica}
></Form.Control>
{
    formik.errors.anagrafica && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.anagrafica}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">dettaglio</label>
<Form.Control type="text" name="dettaglio" className="form-control" value={formik.values.dettaglio}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.dettaglio && !!formik.errors.dettaglio}
isValid ={!!formik.touched.dettaglio && !formik.errors.dettaglio}
></Form.Control>
{
    formik.errors.dettaglio && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.dettaglio}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">visure</label>
<Form.Control type="text" name="visure" className="form-control" value={formik.values.visure}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.visure && !!formik.errors.visure}
isValid ={!!formik.touched.visure && !formik.errors.visure}
></Form.Control>
{
    formik.errors.visure && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.visure}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">bilanci</label>
<Form.Control type="text" name="bilanci" className="form-control" value={formik.values.bilanci}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.bilanci && !!formik.errors.bilanci}
isValid ={!!formik.touched.bilanci && !formik.errors.bilanci}
></Form.Control>
{
    formik.errors.bilanci && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.bilanci}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">protesti</label>
<Form.Control type="text" name="protesti" className="form-control" value={formik.values.protesti}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.protesti && !!formik.errors.protesti}
isValid ={!!formik.touched.protesti && !formik.errors.protesti}
></Form.Control>
{
    formik.errors.protesti && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.protesti}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">catastali</label>
<Form.Control type="text" name="catastali" className="form-control" value={formik.values.catastali}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.catastali && !!formik.errors.catastali}
isValid ={!!formik.touched.catastali && !formik.errors.catastali}
></Form.Control>
{
    formik.errors.catastali && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.catastali}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">reportsmart</label>
<Form.Control type="text" name="reportsmart" className="form-control" value={formik.values.reportsmart}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.reportsmart && !!formik.errors.reportsmart}
isValid ={!!formik.touched.reportsmart && !formik.errors.reportsmart}
></Form.Control>
{
    formik.errors.reportsmart && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.reportsmart}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">deepdossier</label>
<Form.Control type="text" name="deepdossier" className="form-control" value={formik.values.deepdossier}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.deepdossier && !!formik.errors.deepdossier}
isValid ={!!formik.touched.deepdossier && !formik.errors.deepdossier}
></Form.Control>
{
    formik.errors.deepdossier && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.deepdossier}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">executivereport</label>
<Form.Control type="text" name="executivereport" className="form-control" value={formik.values.executivereport}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.executivereport && !!formik.errors.executivereport}
isValid ={!!formik.touched.executivereport && !formik.errors.executivereport}
></Form.Control>
{
    formik.errors.executivereport && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.executivereport}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">reportpersona</label>
<Form.Control type="text" name="reportpersona" className="form-control" value={formik.values.reportpersona}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.reportpersona && !!formik.errors.reportpersona}
isValid ={!!formik.touched.reportpersona && !formik.errors.reportpersona}
></Form.Control>
{
    formik.errors.reportpersona && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.reportpersona}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">reportpersonacompleto</label>
<Form.Control type="text" name="reportpersonacompleto" className="form-control" value={formik.values.reportpersonacompleto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.reportpersonacompleto && !!formik.errors.reportpersonacompleto}
isValid ={!!formik.touched.reportpersonacompleto && !formik.errors.reportpersonacompleto}
></Form.Control>
{
    formik.errors.reportpersonacompleto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.reportpersonacompleto}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

