import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setDati_UtenteList, setDati_UtenteMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getDati_Utente, getOneDati_UtentebyidUtente } from "services/dati_utenteService";
import Layout from "template";
import { Constant } from "template/Constant";
import { Dati_UtenteForm } from "./form";
import { Dati_UtenteTable } from "./table";
import { parseJwtToken } from "services/tokenService";

export const Dati_Utente: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.dati_utente);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const getData = (page, pageSize, searchKey) => {
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    if(jwtToken.data.id === 1){
      getDati_Utente(page, pageSize, searchKey).then((response) => {
        if (response && response.records) {
          dispatch(setDati_UtenteList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        } else {
          dispatch(setDati_UtenteMessage("Non ci sono dati inseriti"));
        }
      })
    }else{
      getOneDati_UtentebyidUtente(jwtToken.data.id).then((response) => {
        if (response && response.data.document.records) {
          dispatch(setDati_UtenteList({ pageNo: page, pageSize: pageSize, list: response.data.document.records, totalCount: response.data.document.total_count, searchKey: searchKey }));
        } else {
          dispatch(setDati_UtenteMessage("Non ci sono dati inseriti"));
        }
      })
    }
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setDati_UtenteMessage(''));
    setAction('edit');
  }
  return (
    <Layout>
      <div className="container-fluid">
        <div className="d-flex flex-column min-vh-100">
          {/* {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setDati_UtenteMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null} */}
          {action ? <Dati_UtenteForm hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Dati_UtenteTable handleRowEdit={handleRowEdit} hideShowForm={setAction} getData={getData} />}
        </div>

      </div>
    </Layout >
  );
};

