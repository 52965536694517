import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setWs_Log_RichiesteMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addWs_Log_Richieste, updateWs_Log_Richieste } from "services/ws_log_richiesteService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Ws_Log_RichiesteForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',data_richiesta:'',esito:'',username:'',servizio:'',richiesta:'',errore:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateWs_Log_Richieste(values);
                if (response) {
                    dispatch(setWs_Log_RichiesteMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_Log_RichiesteMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addWs_Log_Richieste(values);
                if (response) {
                    dispatch(setWs_Log_RichiesteMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_Log_RichiesteMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           data_richiesta: yup.date().required('data_richiesta is required'),
esito: yup.string().required('esito is required'),
username: yup.string().required('username is required'),
servizio: yup.string().required('servizio is required'),
richiesta: yup.string().required('richiesta is required'),
errore: yup.string().required('errore is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Ws_Log_Richieste
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Ws_Log_Richieste</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">data_richiesta</label>
<Form.Control type="text" name="data_richiesta" className="form-control" value={formik.values.data_richiesta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_richiesta && !!formik.errors.data_richiesta}
isValid ={!!formik.touched.data_richiesta && !formik.errors.data_richiesta}
></Form.Control>
{
    formik.errors.data_richiesta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_richiesta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">esito</label>
<Form.Control type="text" name="esito" className="form-control" value={formik.values.esito}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.esito && !!formik.errors.esito}
isValid ={!!formik.touched.esito && !formik.errors.esito}
></Form.Control>
{
    formik.errors.esito && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.esito}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">username</label>
<Form.Control type="text" name="username" className="form-control" value={formik.values.username}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.username && !!formik.errors.username}
isValid ={!!formik.touched.username && !formik.errors.username}
></Form.Control>
{
    formik.errors.username && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.username}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">servizio</label>
<Form.Control type="text" name="servizio" className="form-control" value={formik.values.servizio}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.servizio && !!formik.errors.servizio}
isValid ={!!formik.touched.servizio && !formik.errors.servizio}
></Form.Control>
{
    formik.errors.servizio && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.servizio}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">richiesta</label>
<Form.Control type="text" name="richiesta" className="form-control" value={formik.values.richiesta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.richiesta && !!formik.errors.richiesta}
isValid ={!!formik.touched.richiesta && !formik.errors.richiesta}
></Form.Control>
{
    formik.errors.richiesta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.richiesta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">errore</label>
<Form.Control type="text" name="errore" className="form-control" value={formik.values.errore}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.errore && !!formik.errors.errore}
isValid ={!!formik.touched.errore && !formik.errors.errore}
></Form.Control>
{
    formik.errors.errore && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.errore}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

