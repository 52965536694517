import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import ws_prodotti from "redux/slices/ws_prodotti";
import ws_log_richieste from "redux/slices/ws_log_richieste";
import ws_login from "redux/slices/ws_login";
import ws_addebiti from "redux/slices/ws_addebiti";

import elenco_imprese_esteso_estrazione from "redux/slices/elenco_imprese_esteso_estrazione";
import elenco_imprese_esteso from "redux/slices/elenco_imprese_esteso";
import elenco_imprese_esteso_grafici from "redux/slices/elenco_imprese_esteso_grafici";

import elenco_imprese from "redux/slices/elenco_imprese";

import tipologie_abbonamento from "redux/slices/tipologie_abbonamento";
import piano_estrazioni from "redux/slices/piano_estrazioni";
import piano_abbonamento from "redux/slices/piano_abbonamento";
import trova_impresa from "redux/slices/trova_impresa";
import { createAction } from "@reduxjs/toolkit";
import store from "./store";
import ws_provincieinfocamere from "redux/slices/ws_provincieinfocamere";
import ws_ticket from "redux/slices/ws_ticket";
import archivio from "redux/slices/archivio";
import ws_anagrafica_prodotto from "redux/slices/ws_anagrafica_prodotto";
import comuni_catastali from "redux/slices/comuni_catastali";
import codici_ateco from "redux/slices/codici_ateco";
import assistenza from "redux/slices/assistenza";
import area_geografica from "./slices/area_geografica";
import ricerca_unica from "./slices/ricerca_unica";
import dati_utente from "redux/slices/dati_utente";
import ordini from "redux/slices/ordini";
import storico_pacchetti_abbonamento from "redux/slices/storico_pacchetti_abbonamento";




const rootReducer = combineReducers({ template,authToken,ws_prodotti,ws_log_richieste,ws_login,ws_addebiti,elenco_imprese_esteso_estrazione,elenco_imprese_esteso, elenco_imprese_esteso_grafici,
    tipologie_abbonamento, piano_estrazioni, piano_abbonamento, trova_impresa, ws_provincieinfocamere, ws_ticket, archivio, ws_anagrafica_prodotto, elenco_imprese, comuni_catastali,codici_ateco, 
    assistenza, area_geografica, ricerca_unica, dati_utente,ordini,storico_pacchetti_abbonamento });

export type RootState = ReturnType<typeof rootReducer>;

// const resetAction = createAction('reset')
// export const resetReduce = (state, action) => {
//     state = undefined;
//     return rootReducer(state, action)
//   }
  


export default rootReducer;

