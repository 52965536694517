import { APIService } from "services";


export const getDatiAreaGeografica= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/area_geografica.php`, dati_ricerca);
}

export const getDatiNaturaGiuridica= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/natura_giuridica.php`, dati_ricerca);
}

export const getDatiateco= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/ateco.php`, dati_ricerca);
}

export const getRicercaEstesa= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/ricerca_imprese.php`, dati_ricerca);
}

export const getDettaglioAzienda= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/dettaglio_impresa.php`, dati_ricerca);
}

export const getPosizioniSuccessive= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/posizioni_successive.php`, dati_ricerca);
}
export const salvaRicerca= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/salva_ricerca.php`, dati_ricerca);
}
export const ricercaDiretta= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/ricerca_imprese_diretta.php`, dati_ricerca);
}
export const richiestaProdotto= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/richiesta_prodotti.php`, dati_ricerca);
}
export const controlloDisponibilita= (dati_ricerca) => {
    return APIService.api().post(`/piano_abbonamento/controllo_disponibilita.php`, dati_ricerca);
}
export const richiestaReportSmart= (dati_ricerca) => {
return APIService.api().post(`/ricerca_unica/richiesta_reportsmart.php`, dati_ricerca);
}