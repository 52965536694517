import { render } from '@testing-library/react';
import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    //onPositive: () => void;
    onNegative: () => void;
};

const visualizzaUnitaLocali =  (unitaLocali) =>{
    // unitaLocali.forEach((el) =>{
    //     return (<div className="col-sm-12">Denominazione: {el.StatoAttivitaR }</div>)
    // })
    console.log(unitaLocali)
    
    return (unitaLocali.map((item, i) => {
        let progressivo = i + 1;
        return (
        <div className="row">
            <div className="col-sm-12">Progressivo: {progressivo}</div>
            <div className="col-sm-6">Stato Attività: {item.StatoAttivitaR }</div>
            <div className="col-sm-6">Ateco: {item.Ateco07 }</div>
            <div className="col-sm-6">Indirizzo: {item.Indirizzo.Toponimo}  {item.Indirizzo.Via}  {item.Indirizzo.NCivico} -  {item.Indirizzo.Cap}  {item.Indirizzo.Comune} ( {item.Indirizzo.Provincia})</div>

        </div>
        
        
        )
    })
    
    )
} 


const DetailImpresaPuntualeModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    //onPositive,
    onNegative,
    disableButton,
}) => {
    console.log(body)
    if(body != null){
        console.log(body["Denominazione"])
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-9'>
                            <div className="row border-right-5">
                                <div className="col-sm-12">Denominazione: {body["Denominazione"]}</div>
                                <div className="col-sm-6">Codice Fiscale: {body["CodFisc"]}</div>
                                <div className="col-sm-6">Partita Iva: {body["PIva"]}</div>
                                <div className="col-sm-6">Cciaa-NRea: {body["Cciaa"]} - {body["NRea"]}</div>
                                <div className="col-sm-6">Provincia Sede: {body["Indirizzo"]["Provincia"]}</div>
                                <div className="col-sm-6">Indirizzo: {body["Indirizzo"]["Toponimo"]}  {body["Indirizzo"]["Via"]}  {body["Indirizzo"]["NCivico"]} -  {body["Indirizzo"]["Cap"]}  {body["Indirizzo"]["Comune"]} ( {body["Indirizzo"]["Provincia"]})</div>
                                <div className="col-sm-6">Forma Giuridica: {body["NatGiu"]}</div>
                                <div className="col-sm-6">Stato: {body["StatoAttivitaR"]}</div>
                                <div className="col-sm-6">Ateco Sede: {body["Ateco07"]}</div>
                                {body["UnitaLocali"]["UnitaLocale"].length > 0 &&
                                    <div className="col-sm-12">Numero Unita locali: {body["UnitaLocali"]["UnitaLocale"].length }</div>
                                }
                                {/* <div className="col-sm-12">{visualizzaUnitaLocali(body["UnitaLocali"]["UnitaLocale"])}</div> */}
                                
                                {body["UnitaLocali"]["UnitaLocale"].length < 1 &&
                                    <div className="col-sm-12">Nessuna Unità Locale</div>
                                }

                            </div>
                        </div>
                        <div className='col-sm-3'>
                            <div className="col-sm-12"></div>

                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailImpresaPuntualeModal;

