
import { APIService } from "services";

export const passwordAPICALL = async (id) => {
    try{
        const response = await APIService.apiNoAuth().post(`/token/password.php`, {
            "rif": id,
        }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })

        if (response && response.status === 200) {
            const data = response.data;
            return response;
        } else {
            return null;
        }
    }catch(error){
        return null;
    }
}

export const passwordRecuperaAPICALL = async (password, accessToken) => {
    try{
        const response = await APIService.apiNoAuth().post(`/token/password_recupera.php`, {
            "accessToken": accessToken,
            "password": password,

        }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    
        if (response && response.status === 200) {
            const data = response.data;
            return response;
        } else {
            return null;
        }
    }catch(error){
        return null;
    }
}