import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetElenco_Imprese_EstesoToInit, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteElenco_Imprese_Esteso } from "services/elenco_imprese_estesoService";
import { Link } from "react-router-dom";
import DetailElencoImpreseModal from "template/DetailElencoImpreseModal";
import ConfirmationBilancio from "template/ConfirmationBilancio";
import ConfirmationVisura from "template/ConfirmationVisura";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
    getDataBilancio: (cciaa, nrea, getDataBilancio, idprodotto) => void;
    getDatiVisura: (cciaa, nrea, getDataBilancio, idprodotto, tipovisura) => void;
};
export const Elenco_Imprese_EstesoTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData, getDataBilancio, getDatiVisura}) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const rData = useSelector((state: RootState) => state.elenco_imprese_esteso);
    const [row, setRow] = useState(undefined);
    const [showDetail, setShowDetail] = useState(false); 
    const [showDetailElenco, setShowDetailElenco] = useState(false);
    const [showRichiestaBilancio, setRichiestaBilancio] = useState(false);
    const [showRichiestaVisura, setRichiestaVisura] = useState(false);
    const handleSearch = (search) => {
        setSearch(search);
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }

    const handleRowConfermaRichiestaBilancioClick = (row) => {
        setRowData(row);
        setRichiestaBilancio(true);
    }

    const handleRowConfermaRichiestaVisuraClick = (row) => {
        setRowData(row);
        setRichiestaVisura(true);
    }


    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetElenco_Imprese_EstesoToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }
    })

    const handleReset = () => {
        setSearch('');
        dispatch(resetElenco_Imprese_EstesoToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteElenco_Imprese_Esteso(rowData.progressivo);
            if (response) {
                dispatch(resetElenco_Imprese_EstesoToInit());
                dispatch(setElenco_Imprese_EstesoMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
            }
        }
    }

    const handleServerDettaglio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowDetail(rowData);
            
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleServerBilancio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowBilancio(rowData.provincia_nrea,rowData.n_rea, rowData.denominazione);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleServerVisura = async () => {
        if (rowData) {
            setRichiestaVisura(false);
            handleRowVisura(rowData.provincia_nrea,rowData.n_rea, rowData.denominazione, rowData.stato_attivita);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setElenco_Imprese_EstesoMessage(''));
        hideShowForm('add');
    }

    const handleRowDetail = (rowData) => {
        setRow(rowData);
        setShowDetailElenco(true);
      }
    
      const handleRowBilancio = (cciaa, nrea, nomeanagrafica) => {
        const idprodotto = 6;
        getDataBilancio(cciaa, nrea, nomeanagrafica, idprodotto);
      }

      const handleRowVisura = (cciaa, nrea, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        if(stato_attivita === 'cancellata'){
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISSTO");
        }else{
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISORDIN");
        }
        
      }

      const handleRowEnableBilancio = (row) => {

        if(row.data_ultimo_bilancio_xbrl === '' || row.data_ultimo_bilancio_xbrl == null ){
            return (<i className="fa fa-times-circle icon-size-normal color-icon-notfound" aria-hidden="true"></i>)
        }else{
            if(row.stato_attivita === 'Cancellata'){
                return(<Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}><i className="fa fa-pie-chart icon-size-normal color-icon-notfound" aria-hidden="true"></i></Button>);
            }else{
                return(<Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}><i className="fa fa-pie-chart icon-size-normal" aria-hidden="true"></i></Button>);
            }
            
            
        }
      }

      const handleRowEnableVisura = (row) => {

        if(row.stato_attivita === 'Cancellata'){
            return(<Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaVisuraClick(row)}><i className="fa fa-file-text icon-size-normal color-icon-notfound" aria-hidden="true"></i></Button>);
        }else{
            
            return(<Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaVisuraClick(row)}><i className="fa fa-file-text icon-size-normal" aria-hidden="true"></i></Button>);
        }
      }

      const handleRowDettaglio = (row) => {

        if(row.stato_attivita === 'Cancellata'){
            return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal color-icon-notfound"></i></Button>);
        }else{
            
            return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal"></i></Button>);
        }
      }

    const columns = [
        {
            name: 'Dettaglio',
            button: true,
            cell: (row) => handleRowDettaglio(row),
        },
        {
            name: 'Visura Camerale',
            button: true,
            cell: (row) => handleRowEnableVisura(row),
        },
        {
            name: 'Ultimo Bilancio',
            button: true,
            cell: (row) => handleRowEnableBilancio(row),
        },

    //    {name: 'progressivo', selector: row => row.progressivo, sortable: true},
{name: 'denominazione', selector: row => row.denominazione, sortable: true},
{name: 'provincia_nrea', selector: row => row.provincia_nrea, sortable: true},
{name: 'n_rea', selector: row => row.n_rea, sortable: true},
{name: 'codice_fiscale', selector: row => row.codice_fiscale, sortable: true},
{name: 'partita_iva', selector: row => row.partita_iva, sortable: true},
{name: 'tipo_sede', selector: row => row.tipo_sede, sortable: true},
{name: 'progressivo_localizzazione', selector: row => row.progressivo_localizzazione, sortable: true},
{name: 'n_albo_artigiani', selector: row => row.n_albo_artigiani, sortable: true},
{name: 'cod_sezione_registro', selector: row => row.cod_sezione_registro, sortable: true},
{name: 'sezione_registro', selector: row => row.sezione_registro, sortable: true},
{name: 'cod_forma_giuridica', selector: row => row.cod_forma_giuridica, sortable: true},
{name: 'forma_giuridica', selector: row => row.forma_giuridica, sortable: true},
{name: 'sito_internet', selector: row => row.sito_internet, sortable: true},
{name: 'email', selector: row => row.email, sortable: true},
{name: 'data_iscrizione', selector: row => row.data_iscrizione, sortable: true},
{name: 'data_cancellazione', selector: row => row.data_cancellazione, sortable: true},
{name: 'data_ultimo_protocollo', selector: row => row.data_ultimo_protocollo, sortable: true},
{name: 'codice_toponimo', selector: row => row.codice_toponimo, sortable: true},
{name: 'toponimo', selector: row => row.toponimo, sortable: true},
{name: 'indirizzo', selector: row => row.indirizzo, sortable: true},
{name: 'numero_civico', selector: row => row.numero_civico, sortable: true},
{name: 'codice_stradario', selector: row => row.codice_stradario, sortable: true},
{name: 'cap', selector: row => row.cap, sortable: true},
{name: 'codice_comune_catastale', selector: row => row.codice_comune_catastale, sortable: true},
{name: 'codice_comune_camerale', selector: row => row.codice_comune_camerale, sortable: true},
{name: 'comune', selector: row => row.comune, sortable: true},
{name: 'sigla_provincia', selector: row => row.sigla_provincia, sortable: true},
{name: 'provincia_istat', selector: row => row.provincia_istat, sortable: true},
{name: 'sigla_provincia_camerale', selector: row => row.sigla_provincia_camerale, sortable: true},
{name: 'frazione', selector: row => row.frazione, sortable: true},
{name: 'altre_indicazioni_indirizzo', selector: row => row.altre_indicazioni_indirizzo, sortable: true},
{name: 'codice_stato_estero', selector: row => row.codice_stato_estero, sortable: true},
{name: 'stato_estero', selector: row => row.stato_estero, sortable: true},
{name: 'anno_dichiarazione_addetti', selector: row => row.anno_dichiarazione_addetti, sortable: true},
{name: 'addetti_indipendenti', selector: row => row.addetti_indipendenti, sortable: true},
{name: 'addetti_dipendenti', selector: row => row.addetti_dipendenti, sortable: true},
{name: 'telefono', selector: row => row.telefono, sortable: true},
{name: 'capitale_sociale', selector: row => row.capitale_sociale, sortable: true},
{name: 'valuta', selector: row => row.valuta, sortable: true},
{name: 'attivita_dichiarata', selector: row => row.attivita_dichiarata, sortable: true},
{name: 'codice_ateco_importanza', selector: row => row.codice_ateco_importanza, sortable: true},
{name: 'codice_stato_attivita', selector: row => row.codice_stato_attivita, sortable: true},
{name: 'stato_attivita', selector: row => row.stato_attivita, sortable: true},
{name: 'data_ultimo_bilancio_xbrl', selector: row => row.data_ultimo_bilancio_xbrl, sortable: true},
{name: 'valore_produzione', selector: row => row.valore_produzione, sortable: true},
{name: 'ricavi', selector: row => row.ricavi, sortable: true},
{name: 'utile_perdite', selector: row => row.utile_perdite, sortable: true},
{name: 'opzionale', selector: row => row.opzionale, sortable: true},

       
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Aggiorna Elenco ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    {/* <Link to="/elenco_imprese_grafici"> Visualizza Grafici </Link> */}
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </h6>
            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearch}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>
                <div className="table-responsive">
                    <DataTable
                        selectableRows={false}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div>
            </Card.Body>
            <DetailElencoImpreseModal buttonNegative="Chiudi" buttonPositive="Chiudi" title="Dettaglio Impresa" show={showDetailElenco} body={row} onNegative={() => setShowDetailElenco(false)} />
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Continua" title="Richiesta documento" show={showDelete} body={"Sei Sicuto?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDettaglio} />
            <ConfirmationBilancio buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showRichiestaBilancio} body={"Sei sicuro di voler richiedere il bilancio di questa società?"} onNegative={() => setRichiestaBilancio(false)} onPositive={handleServerBilancio} />
            <ConfirmationVisura buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showRichiestaVisura} body={"Sei sicuro di voler richiedere la visura di questa società?"} onNegative={() => setRichiestaVisura(false)} onPositive={handleServerVisura} />
        </Card>
    );
}

