import { APIService } from "services";

export const getElenco_Imprese_Esteso_Estrazione = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllElenco_Imprese_Esteso_Estrazione(pageNo,pageSize);
    }
    else{
        try {
            res = await searchElenco_Imprese_Esteso_Estrazione(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getDatiEstrazioneBilancioPerProvincia = async (pageNo,pageSize,search,data) => {
    let res;
    if(search.length===0) {
        res = await getAllDatiEstrazioneBilancioPerProvincia(pageNo,pageSize,"31/12/2021");
    }
    else{
        try {
            res = await searchElenco_Imprese_Esteso_Estrazione(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getElenco_Imprese_Esteso_Estrazione_user_id = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllElenco_Imprese_Esteso_Estrazione_utente(pageNo,pageSize);
    }
    else{
        try {
            res = await searchElenco_Imprese_Esteso_Estrazione_utente(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllElenco_Imprese_Esteso_Estrazione = (pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllElenco_Imprese_Esteso_Estrazione_utente = (pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/read_by_user_id.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllDatiEstrazioneBilancioPerProvincia = (pageno,pagesize, data) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/read_bilinaci_group_by_provincia.php?data=${data}`)
}
export const getOneElenco_Imprese_Esteso_Estrazione = (id) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/read_one.php?id=${id}`)
}
export const searchElenco_Imprese_Esteso_Estrazione = (key,pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchElenco_Imprese_Esteso_Estrazione_utente = (key,pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso_estrazione/search_utente.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addElenco_Imprese_Esteso_Estrazione = (data) => {
return APIService.api().post(`/elenco_imprese_esteso_estrazione/create.php`,data)
}
export const updateElenco_Imprese_Esteso_Estrazione = (data) => {
return APIService.api().post(`/elenco_imprese_esteso_estrazione/update.php`,data)
}
export const deleteElenco_Imprese_Esteso_Estrazione = (id_impresa) => {
return APIService.api().post(`/elenco_imprese_esteso_estrazione/delete.php`,{id_impresa:id_impresa})
}
