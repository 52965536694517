import { APIService } from "services";


export const getListaSalvate= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/lista_ricerche.php`, dati_ricerca);
}

export const getListaAcquistate= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/lista_ricerche.php`, dati_ricerca);
}

export const salvaListaRicerca= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/salva_lista_ricerca.php`, dati_ricerca);
}

export const acquistaListaRicerca= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/acquista_lista_ricerca.php`, dati_ricerca);
}

export const getListaByNomeFile= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/ricerca_nome_lista.php`, dati_ricerca);
}
export const eliminaLista= (dati_ricerca) => {
    return APIService.api().post(`/ricerca_unica/elimina_lista_ricerca.php`, dati_ricerca);
}
