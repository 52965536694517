import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetElenco_Imprese_EstesoToInit, setElenco_Imprese_EstesoMessage } from "redux/actions";
import { Button, Card, Col, Container, Form, InputGroup, Pagination, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteElenco_Imprese_Esteso_Estrazione } from "services/elenco_imprese_esteso_estrazioneService";
import { Link } from "react-router-dom";
import DetailElencoImpreseModal from "template/DetailElencoImpreseModal";
import ConfirmationBilancio from "template/ConfirmationBilancio";
import ConfirmationVisura from "template/ConfirmationVisura";
import moment from "moment";
import { Exception } from "sass";
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { getElencoImpreseFile, getOnePiano_Estrazioni, getPiano_Estrazioni_Utente } from "services/piano_estrazioniService";
import { number } from "yup";
import { IPiano_Estrazioni, setPiano_EstrazioniMessage } from "redux/slices/piano_estrazioni";
import { IElenco_Imprese } from "redux/slices/elenco_imprese";
import DetailAnagraficaImpreseModal from "template/DetailAnagraficaImpreseModal";


type OptionTypeDropDown = {
    value: string;
    label: string;
};


type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
    getDataBilancio: (cciaa, nrea, getDataBilancio, idprodotto) => void;
    getDatiVisura: (cciaa, nrea, getDataBilancio, idprodotto, tipovisura) => void;
    getElencoImpresaDaFile: (page, pageSize, searchKey, id, nome_file) => void;
    get_piano_estrazioni:(page, pageSize, searchKey) => void;
};
export const Elenco_Imprese_Table: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData, getDataBilancio, getDatiVisura, getElencoImpresaDaFile, get_piano_estrazioni}) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [rowDataAnagrafica, setRowDataAnagrafica] = useState(undefined);
    const rData = useSelector((state: RootState) => state.elenco_imprese_esteso_estrazione);
    const rDataElenco = useSelector((state: RootState) => state.elenco_imprese);
    const rDataPianoEstrazioni = useSelector((state: RootState) => state.piano_estrazioni);
    const [row, setRow] = useState(undefined);
    const [showDetail, setShowDetail] = useState(false); 
    const [showDetailElenco, setShowDetailElenco] = useState(false);
    const [showRichiestaBilancio, setRichiestaBilancio] = useState(false);
    const [showRichiestaVisura, setRichiestaVisura] = useState(false);


    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [lstElencoImpreseCompleto, setListElencoImpreseCompleto] = useState(elencoImprese);



    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const multiValuePianoEstrazione =  null
    const pianoEstrazioneLista: OptionTypeDropDown [] = []
    const [selectedPianoEstrazione, setPianoEstrazione] = useState(multiValuePianoEstrazione);
    const [selectedPianiEstrazioni, setPianiEstrazioni] = useState(pianoEstrazioneLista);

    
    const [countImprese, setcountImprese] = useState(elencoImprese.length);



    const [filteredList, setFilteredList] = useState(lstElencoImprese);

    const filterBySearch = (event) => {
        const query = event//event.target.value;
        let query_stato = query
        if(query_stato === 'attiva') query_stato = "Registrata Attiva"
        // Create copy of item list
        var updatedList = lstElencoImpreseCompleto //lstElencoImprese //[...lstElencoImprese];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            if(item.anagraficaimpresa.denominazione.toLowerCase().indexOf(query.toLowerCase()) !== -1 || 
            item.anagraficaimpresa.attributi["c-fiscale"].toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.anagraficaimpresa.attributi["partita-iva"].toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
            item.anagraficaimpresa["stato-impresa-attivita"].toLowerCase().indexOf(query_stato.toLowerCase()) !== -1 ||
            item.anagraficaimpresa["forma-giuridica"].toLowerCase().indexOf(query.toLowerCase()) !== -1  ||
            item.anagraficaimpresa.indirizzo.attributi["sgl-provincia"].toLowerCase().indexOf(query.toLowerCase()) !== -1 
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList)
        getAllPosts(updatedList)
    };

    const gestPianoEstrazioni = () => {
        getPiano_Estrazioni_Utente(1, 10000, "").then((response) => {
            if (response) {
                const piani_estrazioni : OptionTypeDropDown[] = []
                response.records?.map((item, i) => {
                const estrazione: OptionTypeDropDown = {
                value: "",
                label: ""
            }
            estrazione.label = item.nome_ricerca
            estrazione.value = item.id.toString()
            piani_estrazioni.push(estrazione)
            })

            setPianiEstrazioni(piani_estrazioni)
                
            } else {
              dispatch(setPiano_EstrazioniMessage("Nessun dato presente in questa sezione"));
            }
          })
    
    }

    const handleSearchPiano = (elenco) => {

        setPianoEstrazione(elenco)

        var datiricerca = {
            "elenco": selectedPianoEstrazione
          }
          getOnePiano_Estrazioni(Number(elenco.value)).then((response) => {
            if (response != null && response.statusText === "OK") {

                var datiricerca = {
                    "servizio": response.data.document.id,
                    "file": response.data.document.nome_file,
                  }
                  getElencoImpreseFile(datiricerca).then((response) => {
                    if (response != null && response.data.message === "OK") {
                      //dispatch(setElenco_ImpreseList({ pageNo: page, pageSize: pageSize, list: response.data.response.dati.listaimprese, totalCount: response.data.NumeroPosizioniTotali, searchKey: searchKey }));
                      //setIsLoading(false);
                      setListElencoImprese(response.data.response.dati.listaimprese)
                      setListElencoImpreseCompleto(response.data.response.dati.listaimprese)
                      getAllPosts(response.data.response.dati.listaimprese)
                    } else {
                      //dispatch(setElenco_ImpreseMessage("Visura non richiedibile"));
                      //setIsLoading(false);
                    }
                  })
            
            }
          })

        
    }


    const getPostData = (data) => {
        return (
          data.map(item => <div className="" key={item.anagraficaimpresa.denominazione}>
            <div className={borderColorInfo(item.anagraficaimpresa)}>
                            <div className="row">
                                <div className="col-sm-1">
                                    {handleRowDettaglio(item.anagraficaimpresa)}
                                </div>
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-sm-12">Denominazione: <strong>{handleValueAnagrafica(item.anagraficaimpresa.denominazione)}</strong></div>
                                        <div className="col-sm-6">Codice Fiscale: <strong>{handleValueAnagrafica(item.anagraficaimpresa.attributi["c-fiscale"])}</strong></div>
                                        <div className="col-sm-6">Partita Iva: <strong>{handleValueAnagrafica(item.anagraficaimpresa.attributi["partita-iva"])}</strong></div>
                                        <div className="col-sm-6">Cciaa-NRea: <strong>{handleValueAnagrafica(item.anagraficaimpresa.attributi["cciaa"])} - {handleValueAnagrafica(item.anagraficaimpresa.attributi["n-rea"])}</strong></div>
                                        <div className="col-sm-6">Provincia Sede: <strong>{handleValueAnagrafica(item.anagraficaimpresa.indirizzo.attributi["sgl-provincia"])}</strong></div>
                                        <div className="col-sm-6">Forma Giuridica: <strong>{handleValueAnagrafica(item.anagraficaimpresa["forma-giuridica"])}</strong></div>
                                        <div className="col-sm-6">Stato: <strong>{handleValueAnagrafica(item.anagraficaimpresa["stato-impresa-attivita"])}</strong></div>
                                        <div className="col-sm-12">Indirizzo: <strong>{handleValueAnagraficaIndirizzo(item.anagraficaimpresa.indirizzo)}</strong></div>
                                        {/* <div className="col-sm-12">Ateco Sede: <strong>{handleValueAnagraficaAttivita(item.anagraficaimpresa["info-attivita"])}</strong></div> */}
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="row">
                                        {/* <div className="col-sm-12">
                                            <Button variant="link" className="btn-sm" onClick={() => handleRowConfermaRichiestaVisuraClick(item.anagraficaimpresa)}>
                                                <i className="fa fa-file-text icon-size-small decoration-none"></i> <span className="font-size-1"><strong>Visura Camerale</strong></span></Button>
                                        </div> */}
                                        <div className="col-sm-12">
                                            {handleRowEnableVisura(item.anagraficaimpresa)}
                                        </div>
                                        <div className="col-sm-12">
                                            {handleRowEnableBilancio(item.anagraficaimpresa)}
                                        </div>
                                       
                                    </div>
                                </div>    
                            </div>
                        </div>
          </div>)
        )
      
      }

      const getAllPosts = async (elenco_input) => {
        let res = lstElencoImprese//rDataElenco
        if(elenco_input != null && elenco_input.length > 0){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{
            //const data = res //res.list;
            //const offset_c = offset + 10;
            //const pageCount_tmp = pageCount;
            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }

        // const data = res //res.list;
        // const slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
      
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
        //setListElencoImprese(lstElencoImprese)
      }
      
      const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
      };
      
      useEffect(() => {
        get_piano_estrazioni(1,1000, "")
        getAllPosts(lstElencoImprese)
        gestPianoEstrazioni()
      }, [offset])


    const handleSearch = (search) => {
        setSearch(search);

        // if (search.length > 10) {

        //     filterBySearch(search)
        //     //getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        // }
        //getElencoImpresaDaFile(Constant.defaultPageNumber, Constant.defaultPageSize, "", 1, "2023022510372054347");
        
    }

    const handleSearchButton = () => {

            filterBySearch(search)
        
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
        //getElencoImpresaDaFile(Constant.defaultPageNumber, Constant.defaultPageSize, "", 1, "2023022510372054347");

    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }

    const handleRowConfermaRichiestaBilancioClick = (row) => {
        setRowData(row);
        setRichiestaBilancio(true);
    }

    const handleRowConfermaRichiestaVisuraClick = (row) => {
        setRowDataAnagrafica(row);
        setRichiestaVisura(true);
    }


    useEffect(() => {
        if (lstElencoImprese && lstElencoImprese.length === 0) {
            //dispatch(resetElenco_Imprese_EstesoToInit());
            //getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            
            //getElencoImpresaDaFile(Constant.defaultPageNumber, Constant.defaultPageSize, "", 1, "2023022510372054347");
            //getAllPosts()

        }

    })

    const handleReset = () => {
        setSearch('');
        //dispatch(resetElenco_Imprese_EstesoToInit());
        //getData(Constant.defaultPageNumber, rData.pageSize, '');
        setListElencoImpreseCompleto(lstElencoImpreseCompleto)
        setListElencoImprese(lstElencoImpreseCompleto)
        getAllPosts(lstElencoImpreseCompleto)
        //getElencoImpresaDaFile(Constant.defaultPageNumber, Constant.defaultPageSize, "", 1, "2023022510372054347");

    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteElenco_Imprese_Esteso_Estrazione(rowData.progressivo);
            if (response) {
                dispatch(resetElenco_Imprese_EstesoToInit());
                dispatch(setElenco_Imprese_EstesoMessage("Deleted Successfully"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
            }
        }
    }

    const handleServerDettaglio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowDetail(rowData);
            
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleServerBilancio = async () => {
        if (rowData) {
            setShowDelete(false);
            handleRowBilancio(rowData["attributi"]["cciaa"],rowData["attributi"]["n-rea"], rowData["denominazione"]);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleServerVisura = async () => {
        if (rowDataAnagrafica) {
            setRichiestaVisura(false);
            handleRowVisura(rowDataAnagrafica["attributi"]["cciaa"],rowDataAnagrafica["attributi"]["n-rea"], rowDataAnagrafica["denominazione"], rowDataAnagrafica["stato-impresa-attivita"]);      
        } else {
            dispatch(setElenco_Imprese_EstesoMessage("Some error occured!"));
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setElenco_Imprese_EstesoMessage(''));
        hideShowForm('add');
    }

    const handleRowDetail = (rowData) => {
        setRow(rowData);
        setShowDetailElenco(true);
      }
    
      const handleRowBilancio = (cciaa, nrea, nomeanagrafica) => {
        const idprodotto = 6;
        getDataBilancio(cciaa, nrea, nomeanagrafica, idprodotto);
      }

      const handleRowVisura = (cciaa, nrea, nomeanagrafica, stato_attivita) => {
        const idprodotto = 7;
        if(stato_attivita !== 'Registrata Attiva'){
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISSTO");
        }else{
            getDatiVisura(cciaa, nrea, nomeanagrafica, idprodotto, "VISORDIN");
        }
        
      }

      const handleRowEnableBilancio = (row) => {

        if(row["dati-bilancio"] === '' || row["dati-bilancio"] == null ){
            return (<p><i className="fa fa-times-circle decoration-none" aria-hidden="true"></i> <strong >Nessun Bilancio</strong></p>)
        }else{
            if(row["stato-impresa-attivita"] !== 'Registrata Attiva'){
                return(<Button variant="link" className="btn-sm padding-zero decoration-none allinea-testo-sx" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}>
                    <i className="fa fa-file-text icon-size-small decoration-none" aria-hidden="true"></i> <strong className="font-size-1 decoration-none">Ultimo Bilancio</strong></Button>);
            }else{
                return(<Button variant="link" className="btn-sm padding-zero decoration-none allinea-testo-sx" onClick={() => handleRowConfermaRichiestaBilancioClick(row)}>
                    <i className="fa fa-file-text icon-size-small decoration-none" aria-hidden="true"></i> <strong className="font-size-1 decoration-none">Ultimo Bilancio</strong></Button>);
            }
            
            
        }
      }

      const handleRowEnableVisura = (row) => {

        if(row["stato-impresa-attivita"] !== 'Registrata Attiva'){
            return(<Button variant="link" className="btn-sm padding-zero decoration-none allinea-testo-sx" onClick={() => handleRowConfermaRichiestaVisuraClick(row)}><i className="fa fa-file-text icon-size-small decoration-none" aria-hidden="true">
                </i> <strong className="font-size-1 decoration-none">Visura Camerale</strong></Button>);
        }else{
            
            return(<Button variant="link" className="btn-sm padding-zero decoration-none allinea-testo-sx allinea-testo-sx" onClick={() => handleRowConfermaRichiestaVisuraClick(row)}><i className="fa fa-file-text icon-size-small decoration-none" aria-hidden="true"></i>
             <strong className="font-size-1 decoration-none"> Visura Camerale</strong></Button>);
        }
      }

      const handleRowDettaglio = (row) => {

        // if(row.stato_attivita === 'Cancellata'){
        //     return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal color-icon-notfound"></i></Button>);
        // }else{
            
            return(<Button variant="link" className="btn-sm center-block-custom" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal"></i></Button>);
        //}
      }

    const borderColorInfo = (row) => {
        if(row["stato-impresa-attivita"] !== 'Registrata Attiva'){
            return "col-sm-12 riga-trova-impresa border-left-8-red background-row-white shadow";
        }else{
            return "col-sm-12 riga-trova-impresa border-left-8-green background-row-white shadow";
        }
    }

    const handleValueAnagrafica = (valore) =>{
        try{
            if(valore == null || valore === undefined ) {
                return "valore non disponibile";
            }else{
                return valore;
            }
        }catch {
            console.log('Error')
        }
    }

    const handleValueAnagraficaAttivita = (valore) =>{
        try{
            if(valore == null || valore === undefined ) {
                return "valore non disponibile";
            }else{
                return valore["attivita"];
            }
        }catch {
            console.log('Error')
        }
        
    }

    const handleValueAnagraficaIndirizzo = (indirizzo: any) =>{
        try{
            if(indirizzo == null || indirizzo === undefined ) {
                return "valore non disponibile";
            }else{
                const indirizzo_completo = handleValueAnagrafica(indirizzo.attributi.toponimo) + " " +
                handleValueAnagrafica(indirizzo.attributi.via) + " " + handleValueAnagrafica(indirizzo.attributi["n-civico"]) + "-" +
                handleValueAnagrafica(indirizzo.attributi.cap) + " " +
                handleValueAnagrafica(indirizzo.attributi.comune) + " ("+ handleValueAnagrafica(indirizzo.attributi["sgl-provincia"]) +")"

                return indirizzo_completo
            }
        }catch {
            console.log('Error')
        }
        
    }


    

    const columns = [
        {
            name: 'Dettaglio',
            button: true,
            cell: (row) => handleRowDettaglio(row),
        },
        // {
        //     name: 'Visura Camerale',
        //     button: true,
        //     cell: (row) => handleRowEnableVisura(row),
        // },
        // {
        //     name: 'Ultimo Bilancio',
        //     button: true,
        //     cell: (row) => handleRowEnableBilancio(row),
        // },
    ];


    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Aggiorna Elenco ({countImprese})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    {/* <Link to="/elenco_imprese_grafici"> Visualizza Grafici </Link> */}
                    {/* <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        <span className="text">Add New</span>
                    </Button> */}
                </h6>
            </Card.Header>
            <Card.Body className="background-custom-card-no-overflow">
                <Row>
                <Col className="ml-auto" md={8} xs={12} xl={8}>
                    <InputGroup>
                    <label className="title-campo-style-elenco larghezza-100">Digita o Seleziona il tuo Elenco Imprese</label>
                    <Select
                         value={selectedPianoEstrazione}
                         onChange={(e) => handleSearchPiano(e)}
                         name="value"
                         options={selectedPianiEstrazioni}
                         className="basic-multi-select"
                         classNamePrefix="select"
                         placeholder="Seleziona Elenco Imprese"
                    />
                    {/* <Button variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchPiano}>
                            Search
                    </Button> */}
                    </InputGroup>
                </Col>
                <Col className="ml-auto" md={4} xs={12} xl={4}>
                    <InputGroup>
                        <label className="title-campo-style-elenco larghezza-100">Ricerca Impresa in Elenco</label>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>

                </Row>
                
                <Col>

                <Row>
                <div className="main-app">

                <ReactPaginate
                    previousLabel={"pre"}
                    nextLabel={"succ"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    //subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
    
                    {/* Display all the posts */}
                    {posts}

                    {/* Using React Paginate */}
                    
                 </div>
                </Row>

                
                

                
                
                </Col>
                





                {/* <div className="table-responsive">
                    <DataTable
                        selectableRows={false}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rDataElenco.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div> */}
            </Card.Body>
            <DetailAnagraficaImpreseModal buttonNegative="Chiudi" buttonPositive="Chiudi" title="Dettaglio Impresa" show={showDetailElenco} body={row} onNegative={() => setShowDetailElenco(false)} />
            <ConfirmationModal buttonNegative="Cancel" buttonPositive="Continua" title="Richiesta documento" show={showDelete} body={"Sei Sicuto?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDettaglio} />
            <ConfirmationBilancio buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showRichiestaBilancio} body={"Sei sicuro di voler richiedere il bilancio di questa società?"} onNegative={() => setRichiestaBilancio(false)} onPositive={handleServerBilancio} />
            <ConfirmationVisura buttonNegative="Annulla" buttonPositive="Continua" title="Richiesta documento" show={showRichiestaVisura} body={"Sei sicuro di voler richiedere la visura di questa società?"} onNegative={() => setRichiestaVisura(false)} onPositive={handleServerVisura} />
        </Card>
    );
}

