import { APIService } from "services";

export const getWs_Provincieinfocamere = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Provincieinfocamere(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Provincieinfocamere(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllWs_Provincieinfocamere = (pageno,pagesize) => {
return APIService.api().get(`/ws_provincieinfocamere/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Provincieinfocamere = (id) => {
return APIService.api().get(`/ws_provincieinfocamere/read_one.php?id=${id}`)
}
export const searchWs_Provincieinfocamere = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_provincieinfocamere/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Provincieinfocamere = (data) => {
return APIService.api().post(`/ws_provincieinfocamere/create.php`,data)
}
export const updateWs_Provincieinfocamere = (data) => {
return APIService.api().post(`/ws_provincieinfocamere/update.php`,data)
}
export const deleteWs_Provincieinfocamere = (SIGLAPROVINCIA) => {
return APIService.api().post(`/ws_provincieinfocamere/delete.php`,{SIGLAPROVINCIA:SIGLAPROVINCIA})
}
