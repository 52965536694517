import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import Layout from "template";
import LoadingSpinner from "../LoadingSpinner";
import ModalErrorGenerico from "template/ModalErrorGenerico";
import ConfirmationDatiObbligatoriElenco from "template/ConfirmationDatiObbligatoriElenco";
import { parseJwtToken } from "services/tokenService";
import { getOneWs_Login } from "services/ws_loginService";
import { getOneDati_UtentebyidUtente } from "services/dati_utenteService";


export const Profilo: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [datiProfilo, setDatiProfilo] = useState(undefined);
  const [datiUtente, setDatiUtente] = useState(undefined);
  const [id_utente, setId_Utente] = useState(false);
  const handleServerDelete = async () => {}

  const token = localStorage.getItem('token');
  const jwtToken = parseJwtToken(token);
  
  let numero_imprese = 0;
  const getDatiProfilo = () => {
    
    setIsLoading(true);
    if(jwtToken){
        let idUtente = jwtToken.data.id;

        if(datiProfilo == null){
            getOneWs_Login(idUtente).then((response) => {
                if (response && response.data && response.data.document) {
                  console.log(response);
                  const iValueUser_set = {
                    id: '',
                    id_utente: response.data.document.id,
                    nome: response.data.document.nome_referente,
                    cognome: response.data.document.cognome_referente,
                    azienda: response.data.document.nome_azienda,
                    email: response.data.document.email,
                    telefono: response.data.document.telefono,
                    partitaiva: response.data.document.partita_iva,
                    descrizione: ''
                }
    
                setDatiProfilo(iValueUser_set);
                setIsLoading(false);

                }else{
                    setIsLoading(false);

                }
              })
        }else{
            setIsLoading(false);
        }
        
    }else{
        setIsLoading(false);
    }
  }

  const getDatiUtente = () => {
    let idUtente = jwtToken.data.id;
    getOneDati_UtentebyidUtente(idUtente).then((response) => {
        setIsLoading(true);
        if (response && response.data && response.data.document) {

            setDatiUtente(response.data.document.records)
            setIsLoading(false);

        }else{
            setIsLoading(false);
        }
    })


  }
  
  useEffect(() => {
    if(datiProfilo == null)getDatiProfilo()
    if(datiUtente == null) getDatiUtente()
    setId_Utente(jwtToken.data.id)
    
  }, []);

  const valoreDato = (dato) => {
    if(dato == null || dato === ""){
        return "Dato non fornito"
    }else{
        return dato
    }
}

const handleInserisci = (id) => {
    navigate("/dati_utente/", {replace: true})
}

const handleModificaProfilo = (id) => {
    navigate("/ws_login/", {replace: true})
}
  
  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid" >
      <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h4>Dati Profilo</h4>
                </div>
                <div className="col-sm-2">
                    <Button variant="info" className="float-right" onClick={() => handleModificaProfilo(id_utente)}><i className="fa-solid fa-plus"></i> Modifica</Button>                 
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
          {datiProfilo != null &&
            <>
                <div className="col-sm-6">Denominazione: <p><strong>{valoreDato(datiProfilo.azienda)}</strong></p></div>
                <div className="col-sm-6">Partita Iva: <p><strong>{valoreDato(datiProfilo.partitaiva)}</strong></p></div>
                <div className="col-sm-6">Cognome Referente: <p><strong>{valoreDato(datiProfilo.cognome)}</strong></p></div>
                <div className="col-sm-6">Nome Referente: <p><strong>{valoreDato(datiProfilo.nome)}</strong></p></div>
                <div className="col-sm-6">Telefono: <p><strong>{valoreDato(datiProfilo.telefono)}</strong></p></div>
                <div className="col-sm-6">Email: <p><strong>{valoreDato(datiProfilo.email)}</strong></p></div>
            </>
        }
          </Row>
        </div>
        <div className="d-flex flex-column" >
          <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-10">
                    <h5>Dati Fatturazione</h5>
                </div>
                <div className="col-sm-2">
                    <Button variant="info" className="float-right" onClick={() => handleInserisci(id_utente)}><i className="fa-solid fa-plus"></i> Inserisci</Button>                 
                </div>
          </Row>
        </div>
        <div className="d-flex flex-column" >
          
          {datiUtente != null && datiUtente.map((utente, i) =>{
            return(
                <>
                <Row className="shadow box-ricerca-elenco row heigth-max margin-row">
                <div className="col-sm-6">Indirizzo: <p><strong>{valoreDato(utente.indirizzo)}</strong></p></div>
                <div className="col-sm-6">Citta: <p><strong>{valoreDato(utente.citta)} {"(" + valoreDato(utente.provincia)+ ")"}</strong></p></div>
                <div className="col-sm-6">Pec: <p><strong>{valoreDato(utente.pec)}</strong></p></div>
                <div className="col-sm-6">Codice univoco: <p><strong>{valoreDato(utente.codice_univoco)}</strong></p></div>
                </Row>
            </>

            )
          })
           
        }
          
        </div>
      </div>
      </Layout >
  );
};

