import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
//import { getLoginByUsername } from "services/ws_loginService";
import { passwordAPICALL } from "services/passwordService";
import { Link } from "react-router-dom";
const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    
    const[invioEmail, setInvioEmail] = useState(null);
    
    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        onSubmit: async (values) => {
            setInvioEmail(true)
            //navigate('/register', { replace: true });

            // getLoginByUsername("aaaa").then((response) => {
            //     console.log(response)
            // })

            passwordAPICALL(values.username).then((response) => {
                console.log(response)
            })
        },
    });

    const handleRecuperPassword = () => {
        setInvioEmail(true)
        //navigate('/register', { replace: true });

        // getLoginByUsername("aaaa").then((response) => {
        //     console.log(response)
        // })
    }

    useEffect(() => {
        setInvioEmail(false)
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-12">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Recupera Password</h1>
                                        </div>
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Inserisci Email</label>
                                                <Form.Control type="text" name="username" className="form-control-user" value={formik.values.username}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.username && !!formik.errors.username}
                                                    isValid={!!formik.touched.username && !formik.errors.username}
                                                ></Form.Control>
                                                {formik.errors.username && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.username}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Button type="submit" className="btn-user btn-block btn btn-info btn btn-primary" variant="primary" onClick={handleRecuperPassword} >Invia</Button>
                                        </Form>
                                        <hr />
                                        {rData.errorMessage ?
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert> : null}
                                        {invioEmail &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">Controlla la tua email per completare il recupero password
                                                <Link className="close link-accedi" to={"/"}>
                                                    Ritorna alla Home
                                                </Link>
                                            </Alert>
                                        }
                                        <hr />
                                        <div className="text-center">
                                            <a className="small" href="/">Vai alla login</a>
                                        </div>
                                        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; My Imprese - Tecno Innovis Srl 2024</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default ResetPassword;


