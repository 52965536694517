import LineChart from "components/LineChart";
import { UserData } from "Data";
import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setTrovaImpresaList, setTrovaImpresaMessage, setWs_ProvincieinfocamereList, setWs_ProvincieinfocamereMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getTrovaImpresa } from "services/ricerca_impresa_service";
import Layout from "template";
import { MenuItems } from "template/MenuItems";
import { DashboardCard } from "./DasboardCard";
import { RicercaImpresa } from "./RicercaImpresa";
import Spinner from 'react-bootstrap/Spinner';
import LoadingSpinner from "../LoadingSpinner";
import { getAllWs_Provincieinfocamere, getWs_Provincieinfocamere } from "services/ws_provincieinfocamereService";


export const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('token');
  let nome_azienda = "";
  let partita_iva = "";
  const [action, setAction] = useState('');
  const rData = useSelector((state: RootState) => state.trova_impresa);
  const rDataProvincia = useSelector((state: RootState) => state.ws_provincieinfocamere);
  const [isLoading, setIsLoading] = useState(false);
  let numero_imprese = 0;
  const getData = (page, pageSize, searchKey, datiricerca) => {
    setIsLoading(true);
    getTrovaImpresa(datiricerca, 0, 0).then((response)=> {
      if (response && response.data.response != null && response.data.response.impresa != null) {
        numero_imprese = response.data.NumeroPosizioniTotali
        dispatch(setTrovaImpresaList({ pageNo: page, pageSize: pageSize, list: response.data.response.impresa, totalCount: response.data.NumeroPosizioniTotali, searchKey: searchKey }));
        setIsLoading(false);
      } else {
        dispatch(setTrovaImpresaMessage("Nessun dato presente in questa sezione"));
        setIsLoading(false);
      }
    })
  }

  const getDataProvincie = (page, pageSize) => {
    //setIsLoading(true);
    getWs_Provincieinfocamere(1, 1000, '').then((response)=> {
      if (response && response.records) {
        dispatch(setWs_ProvincieinfocamereList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: ''}));
        //setIsLoading(false);
      } else {
        dispatch(setWs_ProvincieinfocamereMessage("Nessun dato presente in questa sezione"));
        setIsLoading(false);
      }
    })
  }

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        fill: true,
        label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });


  function parseJwt(token) {
    try {
      // Get Token Header
      const base64HeaderUrl = token.split('.')[0];
      const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
      const headerData = JSON.parse(window.atob(base64Header));
  
      // Get Token payload and date's
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const dataJWT = JSON.parse(window.atob(base64));
      dataJWT.header = headerData;
  
      // TODO: add expiration at check ...
      return dataJWT;
    } catch (err) {
      return false;
    }
  }
  
  const jwtDecoded = parseJwt(token) ;
  if(jwtDecoded)
  {
      console.log(jwtDecoded.data.id)
      nome_azienda = jwtDecoded.data.nome_azienda;
      partita_iva = jwtDecoded.data.partita_iva;
      ;

  }
  
  
  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid" >
        <div className="d-sm-flex align-items-center justify-content-between mb-4" >
          <h1 className="h3 mb-0 text-gray-800" > Dashboard </h1>
        </div>
        <div className="d-flex flex-column min-vh-100" >
          <Row>
            <div className="col-sm-12">
              <p>Benvenuto: {nome_azienda}</p>
            </div>
            
          </Row>
          {/* <Row>
            <RicercaImpresa getData={getData} numero_imprese={numero_imprese} getDataProvincie={getDataProvincie}/> 
          </Row> */}
          <Row>
            {MenuItems.map((item, i) => {
              return <DashboardCard key={`Card-${i}`} name={item.title} path={item.path} />
            })}
          </Row>
        </div>
      </div>
      </Layout >
  );
};

