import React from "react";
import { Spinner } from "react-bootstrap";
import "../../styles/loading.css";

export default function LoadingSpinner() {
  return (
    <div className="loading-div">
    <Spinner animation="border" role="status" className="loading-component">
        <span className="visually-hidden"></span>
    </Spinner>
    </div>
  );
}