import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setElenco_Imprese_Esteso_EstrazioneMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addElenco_Imprese_Esteso_Estrazione, updateElenco_Imprese_Esteso_Estrazione } from "services/elenco_imprese_esteso_estrazioneService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Elenco_Imprese_Form: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id_utente:'',id_impresa:'',data_estrazione:'',progressivo:'',provincia_nrea:'',n_rea:'',codice_fiscale:'',partita_iva:'',tipo_sede:'',progressivo_localizzazione:'',n_albo_artigiani:'',cod_sezione_registro:'',sezione_registro:'',cod_forma_giuridica:'',forma_giuridica:'',sito_internet:'',email:'',data_iscrizione:'',data_cancellazione:'',data_ultimo_protocollo:'',denominazione:'',codice_toponimo:'',toponimo:'',indirizzo:'',numero_civico:'',codice_stradario:'',cap:'',codice_comune_catastale:'',codice_comune_camerale:'',comune:'',sigla_provincia:'',provincia_istat:'',sigla_provincia_camerale:'',frazione:'',altre_indicazioni_indirizzo:'',codice_stato_estero:'',stato_estero:'',anno_dichiarazione_addetti:'',addetti_indipendenti:'',addetti_dipendenti:'',telefono:'',capitale_sociale:'',valuta:'',attivita_dichiarata:'',codice_ateco_importanza:'',codice_stato_attivita:'',stato_attivita:'',data_ultimo_bilancio_xbrl:'',valore_produzione:'',ricavi:'',utile_perdite:'',opzionale:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateElenco_Imprese_Esteso_Estrazione(values);
                if (response) {
                    dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addElenco_Imprese_Esteso_Estrazione(values);
                if (response) {
                    dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id_utente: yup.number().required('id_utente is required'),
data_estrazione: yup.date().required('data_estrazione is required'),
progressivo: yup.string().required('progressivo is required'),
provincia_nrea: yup.string().required('provincia_nrea is required'),
n_rea: yup.string().required('n_rea is required'),
codice_fiscale: yup.string().required('codice_fiscale is required'),
partita_iva: yup.string().required('partita_iva is required'),
tipo_sede: yup.string().required('tipo_sede is required'),
progressivo_localizzazione: yup.string().required('progressivo_localizzazione is required'),
n_albo_artigiani: yup.string().required('n_albo_artigiani is required'),
cod_sezione_registro: yup.string().required('cod_sezione_registro is required'),
sezione_registro: yup.string().required('sezione_registro is required'),
cod_forma_giuridica: yup.string().required('cod_forma_giuridica is required'),
forma_giuridica: yup.string().required('forma_giuridica is required'),
sito_internet: yup.string().required('sito_internet is required'),
email: yup.string().required('email is required'),
data_iscrizione: yup.string().required('data_iscrizione is required'),
data_cancellazione: yup.string().required('data_cancellazione is required'),
data_ultimo_protocollo: yup.string().required('data_ultimo_protocollo is required'),
denominazione: yup.string().required('denominazione is required'),
codice_toponimo: yup.string().required('codice_toponimo is required'),
toponimo: yup.string().required('toponimo is required'),
indirizzo: yup.string().required('indirizzo is required'),
numero_civico: yup.string().required('numero_civico is required'),
codice_stradario: yup.string().required('codice_stradario is required'),
cap: yup.string().required('cap is required'),
codice_comune_catastale: yup.string().required('codice_comune_catastale is required'),
codice_comune_camerale: yup.string().required('codice_comune_camerale is required'),
comune: yup.string().required('comune is required'),
sigla_provincia: yup.string().required('sigla_provincia is required'),
provincia_istat: yup.string().required('provincia_istat is required'),
sigla_provincia_camerale: yup.string().required('sigla_provincia_camerale is required'),
frazione: yup.string().required('frazione is required'),
altre_indicazioni_indirizzo: yup.string().required('altre_indicazioni_indirizzo is required'),
codice_stato_estero: yup.string().required('codice_stato_estero is required'),
stato_estero: yup.string().required('stato_estero is required'),
anno_dichiarazione_addetti: yup.string().required('anno_dichiarazione_addetti is required'),
addetti_indipendenti: yup.string().required('addetti_indipendenti is required'),
addetti_dipendenti: yup.string().required('addetti_dipendenti is required'),
telefono: yup.string().required('telefono is required'),
capitale_sociale: yup.string().required('capitale_sociale is required'),
valuta: yup.string().required('valuta is required'),
attivita_dichiarata: yup.string().required('attivita_dichiarata is required'),
codice_ateco_importanza: yup.string().required('codice_ateco_importanza is required'),
codice_stato_attivita: yup.string().required('codice_stato_attivita is required'),
stato_attivita: yup.string().required('stato_attivita is required'),
data_ultimo_bilancio_xbrl: yup.string().required('data_ultimo_bilancio_xbrl is required'),
valore_produzione: yup.string().required('valore_produzione is required'),
ricavi: yup.string().required('ricavi is required'),
utile_perdite: yup.string().required('utile_perdite is required'),
opzionale: yup.string().required('opzionale is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Elenco_Imprese_Esteso_Estrazione
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Elenco_Imprese_Esteso_Estrazione</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id_utente</label>
<Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
></Form.Control>
{
    formik.errors.id_utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_estrazione</label>
<Form.Control type="text" name="data_estrazione" className="form-control" value={formik.values.data_estrazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_estrazione && !!formik.errors.data_estrazione}
isValid ={!!formik.touched.data_estrazione && !formik.errors.data_estrazione}
></Form.Control>
{
    formik.errors.data_estrazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_estrazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">progressivo</label>
<Form.Control type="text" name="progressivo" className="form-control" value={formik.values.progressivo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.progressivo && !!formik.errors.progressivo}
isValid ={!!formik.touched.progressivo && !formik.errors.progressivo}
></Form.Control>
{
    formik.errors.progressivo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.progressivo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">provincia_nrea</label>
<Form.Control type="text" name="provincia_nrea" className="form-control" value={formik.values.provincia_nrea}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.provincia_nrea && !!formik.errors.provincia_nrea}
isValid ={!!formik.touched.provincia_nrea && !formik.errors.provincia_nrea}
></Form.Control>
{
    formik.errors.provincia_nrea && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.provincia_nrea}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">n_rea</label>
<Form.Control type="text" name="n_rea" className="form-control" value={formik.values.n_rea}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.n_rea && !!formik.errors.n_rea}
isValid ={!!formik.touched.n_rea && !formik.errors.n_rea}
></Form.Control>
{
    formik.errors.n_rea && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.n_rea}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_fiscale</label>
<Form.Control type="text" name="codice_fiscale" className="form-control" value={formik.values.codice_fiscale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_fiscale && !!formik.errors.codice_fiscale}
isValid ={!!formik.touched.codice_fiscale && !formik.errors.codice_fiscale}
></Form.Control>
{
    formik.errors.codice_fiscale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_fiscale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">partita_iva</label>
<Form.Control type="text" name="partita_iva" className="form-control" value={formik.values.partita_iva}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.partita_iva && !!formik.errors.partita_iva}
isValid ={!!formik.touched.partita_iva && !formik.errors.partita_iva}
></Form.Control>
{
    formik.errors.partita_iva && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.partita_iva}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">tipo_sede</label>
<Form.Control type="text" name="tipo_sede" className="form-control" value={formik.values.tipo_sede}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tipo_sede && !!formik.errors.tipo_sede}
isValid ={!!formik.touched.tipo_sede && !formik.errors.tipo_sede}
></Form.Control>
{
    formik.errors.tipo_sede && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tipo_sede}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">progressivo_localizzazione</label>
<Form.Control type="text" name="progressivo_localizzazione" className="form-control" value={formik.values.progressivo_localizzazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.progressivo_localizzazione && !!formik.errors.progressivo_localizzazione}
isValid ={!!formik.touched.progressivo_localizzazione && !formik.errors.progressivo_localizzazione}
></Form.Control>
{
    formik.errors.progressivo_localizzazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.progressivo_localizzazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">n_albo_artigiani</label>
<Form.Control type="text" name="n_albo_artigiani" className="form-control" value={formik.values.n_albo_artigiani}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.n_albo_artigiani && !!formik.errors.n_albo_artigiani}
isValid ={!!formik.touched.n_albo_artigiani && !formik.errors.n_albo_artigiani}
></Form.Control>
{
    formik.errors.n_albo_artigiani && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.n_albo_artigiani}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cod_sezione_registro</label>
<Form.Control type="text" name="cod_sezione_registro" className="form-control" value={formik.values.cod_sezione_registro}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cod_sezione_registro && !!formik.errors.cod_sezione_registro}
isValid ={!!formik.touched.cod_sezione_registro && !formik.errors.cod_sezione_registro}
></Form.Control>
{
    formik.errors.cod_sezione_registro && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cod_sezione_registro}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sezione_registro</label>
<Form.Control type="text" name="sezione_registro" className="form-control" value={formik.values.sezione_registro}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sezione_registro && !!formik.errors.sezione_registro}
isValid ={!!formik.touched.sezione_registro && !formik.errors.sezione_registro}
></Form.Control>
{
    formik.errors.sezione_registro && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sezione_registro}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cod_forma_giuridica</label>
<Form.Control type="text" name="cod_forma_giuridica" className="form-control" value={formik.values.cod_forma_giuridica}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cod_forma_giuridica && !!formik.errors.cod_forma_giuridica}
isValid ={!!formik.touched.cod_forma_giuridica && !formik.errors.cod_forma_giuridica}
></Form.Control>
{
    formik.errors.cod_forma_giuridica && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cod_forma_giuridica}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">forma_giuridica</label>
<Form.Control type="text" name="forma_giuridica" className="form-control" value={formik.values.forma_giuridica}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.forma_giuridica && !!formik.errors.forma_giuridica}
isValid ={!!formik.touched.forma_giuridica && !formik.errors.forma_giuridica}
></Form.Control>
{
    formik.errors.forma_giuridica && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.forma_giuridica}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sito_internet</label>
<Form.Control type="text" name="sito_internet" className="form-control" value={formik.values.sito_internet}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sito_internet && !!formik.errors.sito_internet}
isValid ={!!formik.touched.sito_internet && !formik.errors.sito_internet}
></Form.Control>
{
    formik.errors.sito_internet && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sito_internet}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_iscrizione</label>
<Form.Control type="text" name="data_iscrizione" className="form-control" value={formik.values.data_iscrizione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_iscrizione && !!formik.errors.data_iscrizione}
isValid ={!!formik.touched.data_iscrizione && !formik.errors.data_iscrizione}
></Form.Control>
{
    formik.errors.data_iscrizione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_iscrizione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_cancellazione</label>
<Form.Control type="text" name="data_cancellazione" className="form-control" value={formik.values.data_cancellazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_cancellazione && !!formik.errors.data_cancellazione}
isValid ={!!formik.touched.data_cancellazione && !formik.errors.data_cancellazione}
></Form.Control>
{
    formik.errors.data_cancellazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_cancellazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_ultimo_protocollo</label>
<Form.Control type="text" name="data_ultimo_protocollo" className="form-control" value={formik.values.data_ultimo_protocollo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_ultimo_protocollo && !!formik.errors.data_ultimo_protocollo}
isValid ={!!formik.touched.data_ultimo_protocollo && !formik.errors.data_ultimo_protocollo}
></Form.Control>
{
    formik.errors.data_ultimo_protocollo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_ultimo_protocollo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">denominazione</label>
<Form.Control type="text" name="denominazione" className="form-control" value={formik.values.denominazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.denominazione && !!formik.errors.denominazione}
isValid ={!!formik.touched.denominazione && !formik.errors.denominazione}
></Form.Control>
{
    formik.errors.denominazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.denominazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_toponimo</label>
<Form.Control type="text" name="codice_toponimo" className="form-control" value={formik.values.codice_toponimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_toponimo && !!formik.errors.codice_toponimo}
isValid ={!!formik.touched.codice_toponimo && !formik.errors.codice_toponimo}
></Form.Control>
{
    formik.errors.codice_toponimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_toponimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">toponimo</label>
<Form.Control type="text" name="toponimo" className="form-control" value={formik.values.toponimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.toponimo && !!formik.errors.toponimo}
isValid ={!!formik.touched.toponimo && !formik.errors.toponimo}
></Form.Control>
{
    formik.errors.toponimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.toponimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">indirizzo</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_civico</label>
<Form.Control type="text" name="numero_civico" className="form-control" value={formik.values.numero_civico}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_civico && !!formik.errors.numero_civico}
isValid ={!!formik.touched.numero_civico && !formik.errors.numero_civico}
></Form.Control>
{
    formik.errors.numero_civico && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_civico}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_stradario</label>
<Form.Control type="text" name="codice_stradario" className="form-control" value={formik.values.codice_stradario}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_stradario && !!formik.errors.codice_stradario}
isValid ={!!formik.touched.codice_stradario && !formik.errors.codice_stradario}
></Form.Control>
{
    formik.errors.codice_stradario && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_stradario}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cap</label>
<Form.Control type="text" name="cap" className="form-control" value={formik.values.cap}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cap && !!formik.errors.cap}
isValid ={!!formik.touched.cap && !formik.errors.cap}
></Form.Control>
{
    formik.errors.cap && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cap}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_comune_catastale</label>
<Form.Control type="text" name="codice_comune_catastale" className="form-control" value={formik.values.codice_comune_catastale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_comune_catastale && !!formik.errors.codice_comune_catastale}
isValid ={!!formik.touched.codice_comune_catastale && !formik.errors.codice_comune_catastale}
></Form.Control>
{
    formik.errors.codice_comune_catastale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_comune_catastale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_comune_camerale</label>
<Form.Control type="text" name="codice_comune_camerale" className="form-control" value={formik.values.codice_comune_camerale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_comune_camerale && !!formik.errors.codice_comune_camerale}
isValid ={!!formik.touched.codice_comune_camerale && !formik.errors.codice_comune_camerale}
></Form.Control>
{
    formik.errors.codice_comune_camerale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_comune_camerale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">comune</label>
<Form.Control type="text" name="comune" className="form-control" value={formik.values.comune}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.comune && !!formik.errors.comune}
isValid ={!!formik.touched.comune && !formik.errors.comune}
></Form.Control>
{
    formik.errors.comune && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.comune}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sigla_provincia</label>
<Form.Control type="text" name="sigla_provincia" className="form-control" value={formik.values.sigla_provincia}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sigla_provincia && !!formik.errors.sigla_provincia}
isValid ={!!formik.touched.sigla_provincia && !formik.errors.sigla_provincia}
></Form.Control>
{
    formik.errors.sigla_provincia && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sigla_provincia}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">provincia_istat</label>
<Form.Control type="text" name="provincia_istat" className="form-control" value={formik.values.provincia_istat}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.provincia_istat && !!formik.errors.provincia_istat}
isValid ={!!formik.touched.provincia_istat && !formik.errors.provincia_istat}
></Form.Control>
{
    formik.errors.provincia_istat && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.provincia_istat}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">sigla_provincia_camerale</label>
<Form.Control type="text" name="sigla_provincia_camerale" className="form-control" value={formik.values.sigla_provincia_camerale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.sigla_provincia_camerale && !!formik.errors.sigla_provincia_camerale}
isValid ={!!formik.touched.sigla_provincia_camerale && !formik.errors.sigla_provincia_camerale}
></Form.Control>
{
    formik.errors.sigla_provincia_camerale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.sigla_provincia_camerale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">frazione</label>
<Form.Control type="text" name="frazione" className="form-control" value={formik.values.frazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.frazione && !!formik.errors.frazione}
isValid ={!!formik.touched.frazione && !formik.errors.frazione}
></Form.Control>
{
    formik.errors.frazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.frazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">altre_indicazioni_indirizzo</label>
<Form.Control type="text" name="altre_indicazioni_indirizzo" className="form-control" value={formik.values.altre_indicazioni_indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.altre_indicazioni_indirizzo && !!formik.errors.altre_indicazioni_indirizzo}
isValid ={!!formik.touched.altre_indicazioni_indirizzo && !formik.errors.altre_indicazioni_indirizzo}
></Form.Control>
{
    formik.errors.altre_indicazioni_indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.altre_indicazioni_indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_stato_estero</label>
<Form.Control type="text" name="codice_stato_estero" className="form-control" value={formik.values.codice_stato_estero}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_stato_estero && !!formik.errors.codice_stato_estero}
isValid ={!!formik.touched.codice_stato_estero && !formik.errors.codice_stato_estero}
></Form.Control>
{
    formik.errors.codice_stato_estero && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_stato_estero}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">stato_estero</label>
<Form.Control type="text" name="stato_estero" className="form-control" value={formik.values.stato_estero}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.stato_estero && !!formik.errors.stato_estero}
isValid ={!!formik.touched.stato_estero && !formik.errors.stato_estero}
></Form.Control>
{
    formik.errors.stato_estero && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.stato_estero}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">anno_dichiarazione_addetti</label>
<Form.Control type="text" name="anno_dichiarazione_addetti" className="form-control" value={formik.values.anno_dichiarazione_addetti}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.anno_dichiarazione_addetti && !!formik.errors.anno_dichiarazione_addetti}
isValid ={!!formik.touched.anno_dichiarazione_addetti && !formik.errors.anno_dichiarazione_addetti}
></Form.Control>
{
    formik.errors.anno_dichiarazione_addetti && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.anno_dichiarazione_addetti}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">addetti_indipendenti</label>
<Form.Control type="text" name="addetti_indipendenti" className="form-control" value={formik.values.addetti_indipendenti}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.addetti_indipendenti && !!formik.errors.addetti_indipendenti}
isValid ={!!formik.touched.addetti_indipendenti && !formik.errors.addetti_indipendenti}
></Form.Control>
{
    formik.errors.addetti_indipendenti && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.addetti_indipendenti}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">addetti_dipendenti</label>
<Form.Control type="text" name="addetti_dipendenti" className="form-control" value={formik.values.addetti_dipendenti}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.addetti_dipendenti && !!formik.errors.addetti_dipendenti}
isValid ={!!formik.touched.addetti_dipendenti && !formik.errors.addetti_dipendenti}
></Form.Control>
{
    formik.errors.addetti_dipendenti && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.addetti_dipendenti}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">capitale_sociale</label>
<Form.Control type="text" name="capitale_sociale" className="form-control" value={formik.values.capitale_sociale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.capitale_sociale && !!formik.errors.capitale_sociale}
isValid ={!!formik.touched.capitale_sociale && !formik.errors.capitale_sociale}
></Form.Control>
{
    formik.errors.capitale_sociale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.capitale_sociale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">valuta</label>
<Form.Control type="text" name="valuta" className="form-control" value={formik.values.valuta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.valuta && !!formik.errors.valuta}
isValid ={!!formik.touched.valuta && !formik.errors.valuta}
></Form.Control>
{
    formik.errors.valuta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.valuta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">attivita_dichiarata</label>
<Form.Control type="text" name="attivita_dichiarata" className="form-control" value={formik.values.attivita_dichiarata}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.attivita_dichiarata && !!formik.errors.attivita_dichiarata}
isValid ={!!formik.touched.attivita_dichiarata && !formik.errors.attivita_dichiarata}
></Form.Control>
{
    formik.errors.attivita_dichiarata && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.attivita_dichiarata}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_ateco_importanza</label>
<Form.Control type="text" name="codice_ateco_importanza" className="form-control" value={formik.values.codice_ateco_importanza}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_ateco_importanza && !!formik.errors.codice_ateco_importanza}
isValid ={!!formik.touched.codice_ateco_importanza && !formik.errors.codice_ateco_importanza}
></Form.Control>
{
    formik.errors.codice_ateco_importanza && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_ateco_importanza}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_stato_attivita</label>
<Form.Control type="text" name="codice_stato_attivita" className="form-control" value={formik.values.codice_stato_attivita}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_stato_attivita && !!formik.errors.codice_stato_attivita}
isValid ={!!formik.touched.codice_stato_attivita && !formik.errors.codice_stato_attivita}
></Form.Control>
{
    formik.errors.codice_stato_attivita && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_stato_attivita}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">stato_attivita</label>
<Form.Control type="text" name="stato_attivita" className="form-control" value={formik.values.stato_attivita}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.stato_attivita && !!formik.errors.stato_attivita}
isValid ={!!formik.touched.stato_attivita && !formik.errors.stato_attivita}
></Form.Control>
{
    formik.errors.stato_attivita && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.stato_attivita}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_ultimo_bilancio_xbrl</label>
<Form.Control type="text" name="data_ultimo_bilancio_xbrl" className="form-control" value={formik.values.data_ultimo_bilancio_xbrl}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_ultimo_bilancio_xbrl && !!formik.errors.data_ultimo_bilancio_xbrl}
isValid ={!!formik.touched.data_ultimo_bilancio_xbrl && !formik.errors.data_ultimo_bilancio_xbrl}
></Form.Control>
{
    formik.errors.data_ultimo_bilancio_xbrl && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_ultimo_bilancio_xbrl}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">valore_produzione</label>
<Form.Control type="text" name="valore_produzione" className="form-control" value={formik.values.valore_produzione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.valore_produzione && !!formik.errors.valore_produzione}
isValid ={!!formik.touched.valore_produzione && !formik.errors.valore_produzione}
></Form.Control>
{
    formik.errors.valore_produzione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.valore_produzione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">ricavi</label>
<Form.Control type="text" name="ricavi" className="form-control" value={formik.values.ricavi}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.ricavi && !!formik.errors.ricavi}
isValid ={!!formik.touched.ricavi && !formik.errors.ricavi}
></Form.Control>
{
    formik.errors.ricavi && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.ricavi}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">utile_perdite</label>
<Form.Control type="text" name="utile_perdite" className="form-control" value={formik.values.utile_perdite}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.utile_perdite && !!formik.errors.utile_perdite}
isValid ={!!formik.touched.utile_perdite && !formik.errors.utile_perdite}
></Form.Control>
{
    formik.errors.utile_perdite && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.utile_perdite}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">opzionale</label>
<Form.Control type="text" name="opzionale" className="form-control" value={formik.values.opzionale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.opzionale && !!formik.errors.opzionale}
isValid ={!!formik.touched.opzionale && !formik.errors.opzionale}
></Form.Control>
{
    formik.errors.opzionale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.opzionale}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

