import { APIService } from "services";

export const getWs_Ticket = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Ticket(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Ticket(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getWs_Ticket_User = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Ticket_User(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Ticket_User(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0, esito: "not found" }
    }
    
}

export const getAllWs_Ticket = (pageno,pagesize) => {
return APIService.api().get(`/ws_ticket/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllWs_Ticket_User = (pageno,pagesize) => {
return APIService.api().get(`/ws_ticket/read_by_id_utente.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Ticket = (id) => {
return APIService.api().get(`/ws_ticket/read_one.php?id=${id}`)
}
export const searchWs_Ticket = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_ticket/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchWs_Ticket_User = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_ticket/search_user.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Ticket = (data) => {
return APIService.api().post(`/ws_ticket/create.php`,data)
}
export const updateWs_Ticket = (data) => {
return APIService.api().post(`/ws_ticket/update.php`,data)
}
export const deleteWs_Ticket = (id_ticket) => {
return APIService.api().post(`/ws_ticket/delete.php`,{id_ticket:id_ticket})
}
