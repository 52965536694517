import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IElenco_Imprese_Esteso_Grafici {
    data_estrazione:string,
    provincia_nrea:string,
    cod_forma_giuridica:string,
    forma_giuridica:string,
    data_ultimo_bilancio_xbrl:string,
    numero_imprese: Number,
    capitale_totale_totale: Number,
    valore_produzione_totale: Number,
    ricavi_totale: Number,
    utile_perdite_totale: Number,
    media_capitale_sociale: Number,
    media_valore_produzione: Number,
    media_ricavi: Number,
    media_utile_perdite: Number,
    anno_dichiarazione_addetti: Number,
    addetti_indipendenti_totali: Number,
    addetti_dipendenti_totali: Number,
    addetti_totali: Number,
    media_addetti: Number,
}

interface IElenco_Imprese_Esteso_GraficiData {
    list?: Array<IElenco_Imprese_Esteso_Grafici>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IElenco_Imprese_Esteso_GraficiData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const elenco_imprese_esteso_graficiSlice = createSlice({
    name: "elenco_imprese_esteso_grafici",
    initialState,
    reducers: {
        setElenco_Imprese_Esteso_GraficiList: (state, _action: PayloadAction<IElenco_Imprese_Esteso_GraficiData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetElenco_Imprese_Esteso_GraficiToInit: (state) => {
            state = initialState;
        },
        setElenco_Imprese_Esteso_GraficiMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setElenco_Imprese_Esteso_GraficiList, resetElenco_Imprese_Esteso_GraficiToInit, setElenco_Imprese_Esteso_GraficiMessage } = elenco_imprese_esteso_graficiSlice.actions;

export default elenco_imprese_esteso_graficiSlice.reducer;

