import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IElenco_Imprese {
id_utente:number,
id_abbonamento:number,
codice_abbonamento:string,
numero_anagrafiche_massimo:number,
numero_anagrafiche_residuo:number,
data_apertura:Date,
data_scadenza:Date,
attivo:number,
lista_imprese: []
}

interface IElenco_ImpreseData {
    list?: Array<any>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IElenco_ImpreseData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ElencoImpreseSlice = createSlice({
    name: "elenco_imprese",
    initialState,
    reducers: {
        setElenco_ImpreseList: (state, _action: PayloadAction<IElenco_ImpreseData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetElenco_ImpreseToInit: (state) => {
            state = initialState;
        },
        setElenco_ImpreseMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setElenco_ImpreseList, resetElenco_ImpreseToInit, setElenco_ImpreseMessage } = ElencoImpreseSlice.actions;

export default ElencoImpreseSlice.reducer;

