import { render } from '@testing-library/react';
import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { getCodici_Ateco } from 'services/codici_atecoService';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive?: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive?: () => void;
    onNegative: () => void;
};

const visualizzaUnitaLocali =  (datiRicerca) =>{
    console.log("**************************")
    console.log(datiRicerca)
    var split = null
    if(datiRicerca != null) split = datiRicerca.split(" __")

    return(
        <div>
            {
                split != null && split.length > 0 &&
                <>
                    <div className='col-sm-12'>
                        <p className='icon-confirm-small'><i className="fa fa-check-circle"></i></p>
                    </div>
                    <div className='col-sm-12'>
                        <p><strong>Dati ricerca</strong></p>
                    </div>
                    <div className='col-sm-12'>
                        <div className='row'>
                        {
                            split.map((element, index) => {
                                return(
                                    <div key={index} className="col-sm-6"><p>{element}</p></div>
                                )
                            })
                        }
                        </div>
                    </div>
                    <div className="col-sm-12"><p></p></div>
                    <div className="col-sm-12"><strong>Informazioni: </strong>Attraverso il pulsante <strong>Richiedi Elenco</strong> sarà possibile effettuare nuovamente la ricerca</div>
                </>
                
            }
             {
                split != null && split.length == 0 &&
                <>
                <div className='col-sm-12'>
                    <p className='icon-confirm-small'><i className="fa fa-check-circle"></i></p>
                    </div>
                    <div className='col-sm-12'>
                        <p><strong>Dati ricerca</strong></p>
                    </div>
                    <div className='col-sm-12'>
                    <div className='row'>
                        <p>Ricerca generica senza parametri</p>
                    </div>
                </div>
                </>

             }
             {
                split == null  &&
                <>
                <div className='col-sm-12'>
                    <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                    </div>
                    <div className='col-sm-12'>
                    <div className='row'>
                    <div className='col-sm-12'>
                        <p className='text-alert-center-bold'>Abbiamo rilevato un errore nel recupero delle informazioni</p>
                    </div>
                    </div>
                </div>
                </>

             }
            
        </div>
    )

} 


const DetailListaRicercaModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    console.log(body)
    if(body != null){
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className="row">
                                <div className="col-sm-12">{visualizzaUnitaLocali(body)}</div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    <Button onClick={() => onPositive()} variant="success" disabled={disableButton}>
                        {buttonPositive}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className='row'>
                        <div className='col-sm-12'>
                            <div className="row">
                                <div className="col-sm-12">{visualizzaUnitaLocali(body)}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailListaRicercaModal;

