
import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { setElenco_ImpreseList, setElenco_ImpreseMessage, setElenco_Imprese_Esteso_EstrazioneList, setElenco_Imprese_Esteso_EstrazioneMessage } from "redux/actions";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { getUltimoBilancio } from "services/richiesta_bilancio";
import Layout from "template";
import { Constant } from "template/Constant";
import { Elenco_Imprese_Form } from "./form";
import LoadingSpinner from "../LoadingSpinner";
import { getVisuraCamerale } from "services/richiesta_visura";
import { Elenco_Imprese_Table } from "./table";
import { getElenco_Imprese_Esteso_Estrazione, getElenco_Imprese_Esteso_Estrazione_user_id } from "services/elenco_imprese_esteso_estrazioneService";
import moment from 'moment';
import { getElencoImpreseFile, getPiano_Estrazioni_Utente } from "services/piano_estrazioniService";
import { IPiano_Estrazioni, setPiano_EstrazioniList, setPiano_EstrazioniMessage } from "redux/slices/piano_estrazioni";
import ModalConfirmGenerico from "template/ModalConfirmGenerico";
import ModalErrorGenerico from "template/ModalErrorGenerico";

export const Elenco_Imprese: React.FC = () => {
  const dispatch = useAppDispatch();
  const rData = useSelector((state: RootState) => state.elenco_imprese_esteso_estrazione);
  const rDataElenco = useSelector((state: RootState) => state.elenco_imprese);
  const [row, setRow] = useState(undefined);
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showConfermaRichiesta, setConfermaRichiesta] = useState(false);
  const [showErrore, setErrore] = useState(false);
  const [showMessaggio, setShowMessaggio] = useState("")
  const [showMessaggioErrore, setMessaggioErrore] = useState("Errore nella richiesta")
  

  const getData = (page, pageSize, searchKey) => {
    getElenco_Imprese_Esteso_Estrazione_user_id(page, pageSize, searchKey).then((response) => {
      if (response && response.records) {
        dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
      } else {
        dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Nessun dato presente in questa sezione"));
      }
    })
  }

 const get_piano_estrazioni = (page, pageSize, searchKey) =>{

  // getPiano_Estrazioni_Utente(page, pageSize, searchKey).then((response) => {
  //   if (response) {
  //     let lista = new Array<IPiano_Estrazioni>();
  //     lista.push(response.records)
  //     dispatch(setPiano_EstrazioniList({ pageNo: page, pageSize: pageSize, list: lista, totalCount: lista.length, searchKey: searchKey }));
  //   } else {
  //     dispatch(setPiano_EstrazioniMessage("Nessun dato presente in questa sezione"));
  //   }
  // })

 } 
  

  const getDataBilancio = (cciaa, nrea, nomeanagrafica, idprodotto) => {
    setIsLoading(true);
    var datiricerca = {
      "cciaa": cciaa,
      "nrea": nrea,
      "nomeanagrafica": nomeanagrafica,
      "idprodotto": idprodotto
    }
    getUltimoBilancio(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio Richiesto"));
        setShowMessaggio("Il Bilancio è stato richiesto con successo! Grazie")
        setConfermaRichiesta(true);
        setIsLoading(false);
      } else {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Bilancio non richiedibile"));
        setMessaggioErrore("La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
        setErrore(true)
        setIsLoading(false);
      }
    })
  }

  const getDatiVisura = (cciaa, nrea, nomeanagrafica, idprodotto, tipovisura) => {
    setIsLoading(true);
    var datiricerca = {
      "cciaa": cciaa,
      "nrea": nrea,
      "nomeanagrafica": nomeanagrafica,
      "idprodotto": idprodotto,
      "tipovisura": tipovisura
    }
    getVisuraCamerale(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneList({ pageNo: page, pageSize: pageSize, list: response.records, totalCount: response.total_count, searchKey: searchKey }));
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura Camerale Richiesta"));
        setShowMessaggio("La Visura Camerale è stata richiesta con successo! Grazie")
        setConfermaRichiesta(true);
        setIsLoading(false);
      } else {
        //dispatch(setElenco_Imprese_Esteso_EstrazioneMessage("Visura non richiedibile"));
        setMessaggioErrore("La richiesta della Visura Camerale non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie")
        setErrore(true)
        setIsLoading(false);
      }
    })
  }

  const getElencoImpresaDaFile = (page, pageSize, searchKey, id, nome_file) =>{
    setIsLoading(true);    
    var datiricerca = {
      "servizio": id,
      "file": nome_file,
    }
    getElencoImpreseFile(datiricerca).then((response) => {
      if (response != null && response.data.message === "OK") {
        dispatch(setElenco_ImpreseList({ pageNo: page, pageSize: pageSize, list: response.data.response.dati.listaimprese, totalCount: response.data.NumeroPosizioniTotali, searchKey: searchKey }));
        setIsLoading(false);
      } else {
        dispatch(setElenco_ImpreseMessage("Visura non richiedibile"));
        setIsLoading(false);
      }
    })
  }

  const handleRowEdit = (rowData) => {
    setRow(rowData);
    dispatch(setElenco_Imprese_Esteso_EstrazioneMessage(''));
    setAction('edit');
  }

  const handleServerRedirectTicket = () => {

  }

  const handleServerDefault = () => {

  }

  return (
    <Layout>
      {isLoading ? <LoadingSpinner /> : isLoading}
      <div className="container-fluid">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Elenco Imprese</h1>
        </div>
        <div className="d-flex flex-column min-vh-100">
          {rData.message ?
            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.message}
              <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setElenco_Imprese_Esteso_EstrazioneMessage(''))}>
                <span aria-hidden="true">&times;</span>
              </Button>
            </Alert> : null}
          {action ? <Elenco_Imprese_Form hideShowForm={setAction} action={action} row={row} getData={getData} /> :
            <Elenco_Imprese_Table 
            handleRowEdit={handleRowEdit} 
            hideShowForm={setAction} 
            getData={getData} 
            getDataBilancio={getDataBilancio} 
            getDatiVisura={getDatiVisura} 
            getElencoImpresaDaFile={getElencoImpresaDaFile}
            get_piano_estrazioni={get_piano_estrazioni}/>}
        </div>

      </div>
      <ModalConfirmGenerico 
        buttonNegative="Chiudi"
        buttonPositive="Chiudi"
        title="Richiesta Bilancio" 
        show={showConfermaRichiesta} 
        body={showMessaggio}
        onNegative={() => setConfermaRichiesta(false)}
        onPositive={handleServerRedirectTicket} 
      />
      <ModalErrorGenerico buttonNegative="Chiudi" 
        buttonPositive="Chiudi" 
        title="Ricerca Vuota" 
        show={showErrore} 
        body={showMessaggioErrore} //"La richiesta del Bilancio non è stata effettuata con successo. Riprovare e se il problema persiste contattare il servizio assistenza. Grazie"
        onNegative={() => setErrore(false)} 
        onPositive={handleServerDefault} 
      />
    </Layout >
  );
};

