import { APIService } from "services";

export const getCodici_Ateco = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllCodici_Ateco(pageNo,pageSize);
    }
    else{
        try {
            res = await searchCodici_Ateco(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllCodici_Ateco = (pageno,pagesize) => {
return APIService.api().get(`/codici_ateco/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneCodici_Ateco = (id) => {
return APIService.api().get(`/codici_ateco/read_one.php?id=${id}`)
}
export const searchCodici_Ateco = (key,pageno,pagesize) => {
return APIService.api().get(`/codici_ateco/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addCodici_Ateco = (data) => {
return APIService.api().post(`/codici_ateco/create.php`,data)
}
export const updateCodici_Ateco = (data) => {
return APIService.api().post(`/codici_ateco/update.php`,data)
}
export const deleteCodici_Ateco = (codice) => {
return APIService.api().post(`/codici_ateco/delete.php`,{codice:codice})
}
