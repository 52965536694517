import { APIService } from "services";


export const sendMailRegistrazione= (dati) => {
    return APIService.api().post(`/mail/registrazione`, dati);
}
export const sendMailOrderSuccess = (dati) => {
    return APIService.api().post(`/mail/send_mail_order_suc.php`,dati);
}
export const sendMailOrderFailed = (dati) => {
    return APIService.api().post(`/mail/send_mail_order_fail.php`,dati);
}
export const sendMailAssistenza= (dati) => {
    return APIService.api().post(`/mail/send_mail_assistance.php`, dati);
}
        


// export const getOrdine = (datiOrdine) => {
//     return APIService.apiPay().post('/ordine/creaOrdine', datiOrdine)
// }

// export const creaPagamento = (idOrdine, listaId) => {
//     return APIService.apiPay().post('/pay/crea-pagamento/'+idOrdine, listaId)
// }

// export const aggiornaOrdineId = (idOrdine, stato) => {
//     return APIService.apiPay().post('/ordine/aggiornaOrdine/'+idOrdine +"/" + stato)
// }

// export const getValueMac = (mac) => {
//     return APIService.apiPay().post('/pay/valuemac', mac)
// }

// export const cercaOridineId = (id) => {
//     return APIService.apiPay().post('/ordine/cercaOrdine/'+id)
// }