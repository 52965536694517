import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setPiano_AbbonamentoMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addPiano_Abbonamento, updatePiano_Abbonamento } from "services/piano_abbonamentoService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Piano_AbbonamentoForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id_utente:'',id_abbonamento:'',codice_abbonamento:'',costo:'',attivo:'',numero_ricercaunica_massimo:'',numero_ricercaunica_residuo:'',numero_anagrafiche_massimo:'',numero_anagrafiche_residuo:'',numero_dettaglio_impresa:'',numero_dettaglio_impresa_residuo:'',nunero_bilanci_massimo:'',nunero_bilanci_residuo:'',nunero_visure_massimo:'',nunero_visure_residuo:'',nunero_protesti_massimo:'',nunero_protesti_residuo:'',nunero_catastali_massimo:'',nunero_catastali_residuo:'',numero_reportsmart_massimo:'',numero_reportsmart_residuo:'',numero_deepdossier_massimo:'',numero_deepdossier_residuo:'',numero_executivereport_massimo:'',numero_executivereport_residuo:'',numero_reportpersona_massimo:'',numero_reportpersona_residuo:'',numero_reportpersonacompleto_massimo:'',numero_reportpersonacompleto_residuo:'',data_apertura:'',data_scadenza:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updatePiano_Abbonamento(values);
                if (response) {
                    dispatch(setPiano_AbbonamentoMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPiano_AbbonamentoMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addPiano_Abbonamento(values);
                if (response) {
                    dispatch(setPiano_AbbonamentoMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setPiano_AbbonamentoMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id_utente: yup.number().required('id_utente is required'),
id_abbonamento: yup.number().required('id_abbonamento is required'),
codice_abbonamento: yup.string().required('codice_abbonamento is required'),
costo: yup.number().required('costo is required'),
attivo: yup.number().required('attivo is required'),
numero_ricercaunica_massimo: yup.number().required('numero_ricercaunica_massimo is required'),
numero_ricercaunica_residuo: yup.number().required('numero_ricercaunica_residuo is required'),
numero_anagrafiche_massimo: yup.number().required('numero_anagrafiche_massimo is required'),
numero_anagrafiche_residuo: yup.number().required('numero_anagrafiche_residuo is required'),
numero_dettaglio_impresa: yup.number().required('numero_dettaglio_impresa is required'),
numero_dettaglio_impresa_residuo: yup.number().required('numero_dettaglio_impresa_residuo is required'),
nunero_bilanci_massimo: yup.number().required('nunero_bilanci_massimo is required'),
nunero_bilanci_residuo: yup.number().required('nunero_bilanci_residuo is required'),
nunero_visure_massimo: yup.number().required('nunero_visure_massimo is required'),
nunero_visure_residuo: yup.number().required('nunero_visure_residuo is required'),
nunero_protesti_massimo: yup.number().required('nunero_protesti_massimo is required'),
nunero_protesti_residuo: yup.number().required('nunero_protesti_residuo is required'),
nunero_catastali_massimo: yup.number().required('nunero_catastali_massimo is required'),
nunero_catastali_residuo: yup.number().required('nunero_catastali_residuo is required'),
numero_reportsmart_massimo: yup.number().required('numero_reportsmart_massimo is required'),
numero_reportsmart_residuo: yup.number().required('numero_reportsmart_residuo is required'),
numero_deepdossier_massimo: yup.number().required('numero_deepdossier_massimo is required'),
numero_deepdossier_residuo: yup.number().required('numero_deepdossier_residuo is required'),
numero_executivereport_massimo: yup.number().required('numero_executivereport_massimo is required'),
numero_executivereport_residuo: yup.number().required('numero_executivereport_residuo is required'),
numero_reportpersona_massimo: yup.number().required('numero_reportpersona_massimo is required'),
numero_reportpersona_residuo: yup.number().required('numero_reportpersona_residuo is required'),
numero_reportpersonacompleto_massimo: yup.number().required('numero_reportpersonacompleto_massimo is required'),
numero_reportpersonacompleto_residuo: yup.number().required('numero_reportpersonacompleto_residuo is required'),
data_apertura: yup.date().required('data_apertura is required'),
data_scadenza: yup.date().required('data_scadenza is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Piano_Abbonamento
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Piano_Abbonamento</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id_utente</label>
<Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
></Form.Control>
{
    formik.errors.id_utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_abbonamento</label>
<Form.Control type="text" name="id_abbonamento" className="form-control" value={formik.values.id_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_abbonamento && !!formik.errors.id_abbonamento}
isValid ={!!formik.touched.id_abbonamento && !formik.errors.id_abbonamento}
></Form.Control>
{
    formik.errors.id_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_abbonamento</label>
<Form.Control type="text" name="codice_abbonamento" className="form-control" value={formik.values.codice_abbonamento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_abbonamento && !!formik.errors.codice_abbonamento}
isValid ={!!formik.touched.codice_abbonamento && !formik.errors.codice_abbonamento}
></Form.Control>
{
    formik.errors.codice_abbonamento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_abbonamento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">costo</label>
<Form.Control type="text" name="costo" className="form-control" value={formik.values.costo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.costo && !!formik.errors.costo}
isValid ={!!formik.touched.costo && !formik.errors.costo}
></Form.Control>
{
    formik.errors.costo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.costo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">attivo</label>
<Form.Control type="text" name="attivo" className="form-control" value={formik.values.attivo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.attivo && !!formik.errors.attivo}
isValid ={!!formik.touched.attivo && !formik.errors.attivo}
></Form.Control>
{
    formik.errors.attivo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.attivo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_ricercaunica_massimo</label>
<Form.Control type="text" name="numero_ricercaunica_massimo" className="form-control" value={formik.values.numero_ricercaunica_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_ricercaunica_massimo && !!formik.errors.numero_ricercaunica_massimo}
isValid ={!!formik.touched.numero_ricercaunica_massimo && !formik.errors.numero_ricercaunica_massimo}
></Form.Control>
{
    formik.errors.numero_ricercaunica_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_ricercaunica_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_ricercaunica_residuo</label>
<Form.Control type="text" name="numero_ricercaunica_residuo" className="form-control" value={formik.values.numero_ricercaunica_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_ricercaunica_residuo && !!formik.errors.numero_ricercaunica_residuo}
isValid ={!!formik.touched.numero_ricercaunica_residuo && !formik.errors.numero_ricercaunica_residuo}
></Form.Control>
{
    formik.errors.numero_ricercaunica_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_ricercaunica_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_anagrafiche_massimo</label>
<Form.Control type="text" name="numero_anagrafiche_massimo" className="form-control" value={formik.values.numero_anagrafiche_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_anagrafiche_massimo && !!formik.errors.numero_anagrafiche_massimo}
isValid ={!!formik.touched.numero_anagrafiche_massimo && !formik.errors.numero_anagrafiche_massimo}
></Form.Control>
{
    formik.errors.numero_anagrafiche_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_anagrafiche_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_anagrafiche_residuo</label>
<Form.Control type="text" name="numero_anagrafiche_residuo" className="form-control" value={formik.values.numero_anagrafiche_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_anagrafiche_residuo && !!formik.errors.numero_anagrafiche_residuo}
isValid ={!!formik.touched.numero_anagrafiche_residuo && !formik.errors.numero_anagrafiche_residuo}
></Form.Control>
{
    formik.errors.numero_anagrafiche_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_anagrafiche_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_dettaglio_impresa</label>
<Form.Control type="text" name="numero_dettaglio_impresa" className="form-control" value={formik.values.numero_dettaglio_impresa}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_dettaglio_impresa && !!formik.errors.numero_dettaglio_impresa}
isValid ={!!formik.touched.numero_dettaglio_impresa && !formik.errors.numero_dettaglio_impresa}
></Form.Control>
{
    formik.errors.numero_dettaglio_impresa && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_dettaglio_impresa}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_dettaglio_impresa_residuo</label>
<Form.Control type="text" name="numero_dettaglio_impresa_residuo" className="form-control" value={formik.values.numero_dettaglio_impresa_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_dettaglio_impresa_residuo && !!formik.errors.numero_dettaglio_impresa_residuo}
isValid ={!!formik.touched.numero_dettaglio_impresa_residuo && !formik.errors.numero_dettaglio_impresa_residuo}
></Form.Control>
{
    formik.errors.numero_dettaglio_impresa_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_dettaglio_impresa_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_bilanci_massimo</label>
<Form.Control type="text" name="nunero_bilanci_massimo" className="form-control" value={formik.values.nunero_bilanci_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_bilanci_massimo && !!formik.errors.nunero_bilanci_massimo}
isValid ={!!formik.touched.nunero_bilanci_massimo && !formik.errors.nunero_bilanci_massimo}
></Form.Control>
{
    formik.errors.nunero_bilanci_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_bilanci_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_bilanci_residuo</label>
<Form.Control type="text" name="nunero_bilanci_residuo" className="form-control" value={formik.values.nunero_bilanci_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_bilanci_residuo && !!formik.errors.nunero_bilanci_residuo}
isValid ={!!formik.touched.nunero_bilanci_residuo && !formik.errors.nunero_bilanci_residuo}
></Form.Control>
{
    formik.errors.nunero_bilanci_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_bilanci_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_visure_massimo</label>
<Form.Control type="text" name="nunero_visure_massimo" className="form-control" value={formik.values.nunero_visure_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_visure_massimo && !!formik.errors.nunero_visure_massimo}
isValid ={!!formik.touched.nunero_visure_massimo && !formik.errors.nunero_visure_massimo}
></Form.Control>
{
    formik.errors.nunero_visure_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_visure_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_visure_residuo</label>
<Form.Control type="text" name="nunero_visure_residuo" className="form-control" value={formik.values.nunero_visure_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_visure_residuo && !!formik.errors.nunero_visure_residuo}
isValid ={!!formik.touched.nunero_visure_residuo && !formik.errors.nunero_visure_residuo}
></Form.Control>
{
    formik.errors.nunero_visure_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_visure_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_protesti_massimo</label>
<Form.Control type="text" name="nunero_protesti_massimo" className="form-control" value={formik.values.nunero_protesti_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_protesti_massimo && !!formik.errors.nunero_protesti_massimo}
isValid ={!!formik.touched.nunero_protesti_massimo && !formik.errors.nunero_protesti_massimo}
></Form.Control>
{
    formik.errors.nunero_protesti_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_protesti_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_protesti_residuo</label>
<Form.Control type="text" name="nunero_protesti_residuo" className="form-control" value={formik.values.nunero_protesti_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_protesti_residuo && !!formik.errors.nunero_protesti_residuo}
isValid ={!!formik.touched.nunero_protesti_residuo && !formik.errors.nunero_protesti_residuo}
></Form.Control>
{
    formik.errors.nunero_protesti_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_protesti_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_catastali_massimo</label>
<Form.Control type="text" name="nunero_catastali_massimo" className="form-control" value={formik.values.nunero_catastali_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_catastali_massimo && !!formik.errors.nunero_catastali_massimo}
isValid ={!!formik.touched.nunero_catastali_massimo && !formik.errors.nunero_catastali_massimo}
></Form.Control>
{
    formik.errors.nunero_catastali_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_catastali_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">nunero_catastali_residuo</label>
<Form.Control type="text" name="nunero_catastali_residuo" className="form-control" value={formik.values.nunero_catastali_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nunero_catastali_residuo && !!formik.errors.nunero_catastali_residuo}
isValid ={!!formik.touched.nunero_catastali_residuo && !formik.errors.nunero_catastali_residuo}
></Form.Control>
{
    formik.errors.nunero_catastali_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nunero_catastali_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportsmart_massimo</label>
<Form.Control type="text" name="numero_reportsmart_massimo" className="form-control" value={formik.values.numero_reportsmart_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportsmart_massimo && !!formik.errors.numero_reportsmart_massimo}
isValid ={!!formik.touched.numero_reportsmart_massimo && !formik.errors.numero_reportsmart_massimo}
></Form.Control>
{
    formik.errors.numero_reportsmart_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportsmart_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportsmart_residuo</label>
<Form.Control type="text" name="numero_reportsmart_residuo" className="form-control" value={formik.values.numero_reportsmart_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportsmart_residuo && !!formik.errors.numero_reportsmart_residuo}
isValid ={!!formik.touched.numero_reportsmart_residuo && !formik.errors.numero_reportsmart_residuo}
></Form.Control>
{
    formik.errors.numero_reportsmart_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportsmart_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_deepdossier_massimo</label>
<Form.Control type="text" name="numero_deepdossier_massimo" className="form-control" value={formik.values.numero_deepdossier_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_deepdossier_massimo && !!formik.errors.numero_deepdossier_massimo}
isValid ={!!formik.touched.numero_deepdossier_massimo && !formik.errors.numero_deepdossier_massimo}
></Form.Control>
{
    formik.errors.numero_deepdossier_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_deepdossier_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_deepdossier_residuo</label>
<Form.Control type="text" name="numero_deepdossier_residuo" className="form-control" value={formik.values.numero_deepdossier_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_deepdossier_residuo && !!formik.errors.numero_deepdossier_residuo}
isValid ={!!formik.touched.numero_deepdossier_residuo && !formik.errors.numero_deepdossier_residuo}
></Form.Control>
{
    formik.errors.numero_deepdossier_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_deepdossier_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_executivereport_massimo</label>
<Form.Control type="text" name="numero_executivereport_massimo" className="form-control" value={formik.values.numero_executivereport_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_executivereport_massimo && !!formik.errors.numero_executivereport_massimo}
isValid ={!!formik.touched.numero_executivereport_massimo && !formik.errors.numero_executivereport_massimo}
></Form.Control>
{
    formik.errors.numero_executivereport_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_executivereport_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_executivereport_residuo</label>
<Form.Control type="text" name="numero_executivereport_residuo" className="form-control" value={formik.values.numero_executivereport_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_executivereport_residuo && !!formik.errors.numero_executivereport_residuo}
isValid ={!!formik.touched.numero_executivereport_residuo && !formik.errors.numero_executivereport_residuo}
></Form.Control>
{
    formik.errors.numero_executivereport_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_executivereport_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportpersona_massimo</label>
<Form.Control type="text" name="numero_reportpersona_massimo" className="form-control" value={formik.values.numero_reportpersona_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportpersona_massimo && !!formik.errors.numero_reportpersona_massimo}
isValid ={!!formik.touched.numero_reportpersona_massimo && !formik.errors.numero_reportpersona_massimo}
></Form.Control>
{
    formik.errors.numero_reportpersona_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportpersona_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportpersona_residuo</label>
<Form.Control type="text" name="numero_reportpersona_residuo" className="form-control" value={formik.values.numero_reportpersona_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportpersona_residuo && !!formik.errors.numero_reportpersona_residuo}
isValid ={!!formik.touched.numero_reportpersona_residuo && !formik.errors.numero_reportpersona_residuo}
></Form.Control>
{
    formik.errors.numero_reportpersona_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportpersona_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportpersonacompleto_massimo</label>
<Form.Control type="text" name="numero_reportpersonacompleto_massimo" className="form-control" value={formik.values.numero_reportpersonacompleto_massimo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportpersonacompleto_massimo && !!formik.errors.numero_reportpersonacompleto_massimo}
isValid ={!!formik.touched.numero_reportpersonacompleto_massimo && !formik.errors.numero_reportpersonacompleto_massimo}
></Form.Control>
{
    formik.errors.numero_reportpersonacompleto_massimo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportpersonacompleto_massimo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">numero_reportpersonacompleto_residuo</label>
<Form.Control type="text" name="numero_reportpersonacompleto_residuo" className="form-control" value={formik.values.numero_reportpersonacompleto_residuo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.numero_reportpersonacompleto_residuo && !!formik.errors.numero_reportpersonacompleto_residuo}
isValid ={!!formik.touched.numero_reportpersonacompleto_residuo && !formik.errors.numero_reportpersonacompleto_residuo}
></Form.Control>
{
    formik.errors.numero_reportpersonacompleto_residuo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.numero_reportpersonacompleto_residuo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_apertura</label>
<Form.Control type="text" name="data_apertura" className="form-control" value={formik.values.data_apertura}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_apertura && !!formik.errors.data_apertura}
isValid ={!!formik.touched.data_apertura && !formik.errors.data_apertura}
></Form.Control>
{
    formik.errors.data_apertura && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_apertura}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_scadenza</label>
<Form.Control type="text" name="data_scadenza" className="form-control" value={formik.values.data_scadenza}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_scadenza && !!formik.errors.data_scadenza}
isValid ={!!formik.touched.data_scadenza && !formik.errors.data_scadenza}
></Form.Control>
{
    formik.errors.data_scadenza && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_scadenza}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

