import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWs_Log_Richieste {
id:number,
data_richiesta:Date,
esito:string,
username:string,
servizio:string,
richiesta:string,
errore:string
}

interface IWs_Log_RichiesteData {
    list?: Array<IWs_Log_Richieste>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWs_Log_RichiesteData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ws_log_richiesteSlice = createSlice({
    name: "ws_log_richieste",
    initialState,
    reducers: {
        setWs_Log_RichiesteList: (state, _action: PayloadAction<IWs_Log_RichiesteData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWs_Log_RichiesteToInit: (state) => {
            state = initialState;
        },
        setWs_Log_RichiesteMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWs_Log_RichiesteList, resetWs_Log_RichiesteToInit, setWs_Log_RichiesteMessage } = ws_log_richiesteSlice.actions;

export default ws_log_richiesteSlice.reducer;

