import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onPositive: () => void;
    onNegative: () => void;
};

const ConfirmationDifferitaReport: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onPositive,
    onNegative,
    disableButton,
}) => {
    if(body != null){

        if(body["status"] === 200){
            return (
                <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-confirm'><i className="fa fa-check-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className='text-alert-center-bold'>Richiesta del servizio {body["prodotto"]} effettuata con successo!</p>
                                    <p className='text-alert-center-bold'>A breve sarà possibile la consultazione</p>
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                            {buttonNegative}
                        </Button>
                        <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                            {buttonPositive}
                        </Button>
                    </Modal.Footer>
                </Modal>
            );
        }else{

            return (
                <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row'>
                                <div className='col-sm-12'>
                                    <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                                </div>
                                <div className='col-sm-12'>
                                    <p className='text-alert-center-bold'>Abbiamo rilevato un errore nel rilascio del servizio</p>
                                </div>
                            </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                            {buttonNegative}
                        </Button>
                    </Modal.Footer>
                </Modal>
            );


        }
        
    }else{
        return (
            <Modal size="lg" show={show} onHide={() => onNegative()} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                            <div className='col-sm-12'>
                                <p className='icon-alert'><i className="fa fa-exclamation-circle"></i></p>
                            </div>
                            <div className='col-sm-12'>
                                <p className='text-alert-center-bold'>Abbiamo rilevato un errore nel rilascio del servizio</p>
                            </div>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

};

export default ConfirmationDifferitaReport;

