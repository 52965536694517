import { APIService } from "services";

export const getWs_Anagrafica_Prodotto = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllWs_Anagrafica_Prodotto(pageNo,pageSize);
    }
    else{
        try {
            res = await searchWs_Anagrafica_Prodotto(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllWs_Anagrafica_Prodotto = (pageno,pagesize) => {
return APIService.api().get(`/ws_anagrafica_prodotto/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneWs_Anagrafica_Prodotto = (id) => {
return APIService.api().get(`/ws_anagrafica_prodotto/read_one.php?id=${id}`)
}
export const searchWs_Anagrafica_Prodotto = (key,pageno,pagesize) => {
return APIService.api().get(`/ws_anagrafica_prodotto/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addWs_Anagrafica_Prodotto = (data) => {
return APIService.api().post(`/ws_anagrafica_prodotto/create.php`,data)
}
export const updateWs_Anagrafica_Prodotto = (data) => {
return APIService.api().post(`/ws_anagrafica_prodotto/update.php`,data)
}
export const deleteWs_Anagrafica_Prodotto = (id_ticket) => {
return APIService.api().post(`/ws_anagrafica_prodotto/delete.php`,{id_ticket:id_ticket})
}
