import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICodici_Ateco {
codice:string,
sigla:string,
descrizione:string
}

interface ICodici_AtecoData {
    list?: Array<ICodici_Ateco>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ICodici_AtecoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const codici_atecoSlice = createSlice({
    name: "codici_ateco",
    initialState,
    reducers: {
        setCodici_AtecoList: (state, _action: PayloadAction<ICodici_AtecoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetCodici_AtecoToInit: (state) => {
            state = initialState;
        },
        setCodici_AtecoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setCodici_AtecoList, resetCodici_AtecoToInit, setCodici_AtecoMessage } = codici_atecoSlice.actions;

export default codici_atecoSlice.reducer;

