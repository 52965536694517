import { APIService } from "services";

export const getComuni_Catastali = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllComuni_Catastali(pageNo,pageSize);
    }
    else{
        try {
            res = await searchComuni_Catastali(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllComuni_Catastali = (pageno,pagesize) => {
return APIService.api().get(`/comuni_catastali/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneComuni_Catastali = (id) => {
return APIService.api().get(`/comuni_catastali/read_one.php?id=${id}`)
}
export const searchComuni_Catastali = (key,pageno,pagesize) => {
return APIService.api().get(`/comuni_catastali/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addComuni_Catastali = (data) => {
return APIService.api().post(`/comuni_catastali/create.php`,data)
}
export const updateComuni_Catastali = (data) => {
return APIService.api().post(`/comuni_catastali/update.php`,data)
}
export const deleteComuni_Catastali = (codicecomune) => {
return APIService.api().post(`/comuni_catastali/delete.php`,{codicecomune:codicecomune})
}
