import React from "react";
import { Line } from "react-chartjs-2";

//import { Chart as ChartJS } from "chart.js/auto";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );
function LineChart({ chartData, id = "lineid" }) {
  return <Line id={id} className="center-block-custom dimensione-chart" data={chartData}/>;
}

export default LineChart;