import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPiano_Abbonamento {
id_utente:number,
id_abbonamento:number,
codice_abbonamento:string,
costo:number,
attivo:number,
numero_ricercaunica_massimo:number,
numero_ricercaunica_residuo:number,
numero_anagrafiche_massimo:number,
numero_anagrafiche_residuo:number,
numero_dettaglio_impresa:number,
numero_dettaglio_impresa_residuo:number,
nunero_bilanci_massimo:number,
nunero_bilanci_residuo:number,
nunero_visure_massimo:number,
nunero_visure_residuo:number,
nunero_protesti_massimo:number,
nunero_protesti_residuo:number,
nunero_catastali_massimo:number,
nunero_catastali_residuo:number,
numero_reportsmart_massimo:number,
numero_reportsmart_residuo:number,
numero_deepdossier_massimo:number,
numero_deepdossier_residuo:number,
numero_executivereport_massimo:number,
numero_executivereport_residuo:number,
numero_reportpersona_massimo:number,
numero_reportpersona_residuo:number,
numero_reportpersonacompleto_massimo:number,
numero_reportpersonacompleto_residuo:number,
data_apertura:Date,
data_scadenza:Date
}

interface IPiano_AbbonamentoData {
    list?: Array<IPiano_Abbonamento>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IPiano_AbbonamentoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const piano_abbonamentoSlice = createSlice({
    name: "piano_abbonamento",
    initialState,
    reducers: {
        setPiano_AbbonamentoList: (state, _action: PayloadAction<IPiano_AbbonamentoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetPiano_AbbonamentoToInit: (state) => {
            state = initialState;
        },
        setPiano_AbbonamentoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setPiano_AbbonamentoList, resetPiano_AbbonamentoToInit, setPiano_AbbonamentoMessage } = piano_abbonamentoSlice.actions;

export default piano_abbonamentoSlice.reducer;

