import { APIService } from "services";


export const getOrdineTest= () => {
    return APIService.apiPay().post(`/ordine/test`);
}


export const getOrdine = (datiOrdine) => {
    return APIService.apiPay().post('/ordine/creaOrdine', datiOrdine)
}

export const creaPagamento = (idOrdine, listaId) => {
    return APIService.apiPay().post('/pay/crea-pagamento/'+idOrdine, listaId)
}

export const aggiornaOrdineId = (idOrdine, stato) => {
    return APIService.apiPay().post('/ordine/aggiornaOrdine/'+idOrdine +"/" + stato)
}


export const aggiornaOrdine = (idOrdine, stato, dati) => {
    return APIService.apiPay().post('/ordine/aggiornaOrdine/'+idOrdine +"/" + stato, dati)
}

export const getValueMac = (mac) => {
    return APIService.apiPay().post('/pay/valuemac', mac)
}

export const cercaOridineId = (id) => {
    return APIService.apiPay().post('/ordine/cercaOrdine/'+id)
}

export const cancellaOrdineId = (id) => {
    return APIService.apiPay().post('/ordine/cancellaOrdine/'+id)
}

// export const getPacchettiOrdine = (iduser, idabbonamento) => {
//     return APIService.apiPay().post(`/storico/utente/${iduser}/abbonamento/${idabbonamento}`)
// }
export const getPacchettiOrdine = (idordine) => {
    return APIService.apiPay().post(`/storico/cercaOrdine/${idordine}`)
}