import { APIService } from "services";

export const getElenco_Imprese_Esteso = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllElenco_Imprese_Esteso(pageNo,pageSize);
    }
    else{
        try {
            res = await searchElenco_Imprese_Esteso(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllElenco_Imprese_Esteso = (pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneElenco_Imprese_Esteso = (id) => {
return APIService.api().get(`/elenco_imprese_esteso/read_one.php?id=${id}`)
}
export const searchElenco_Imprese_Esteso = (key,pageno,pagesize) => {
return APIService.api().get(`/elenco_imprese_esteso/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addElenco_Imprese_Esteso = (data) => {
return APIService.api().post(`/elenco_imprese_esteso/create.php`,data)
}
export const updateElenco_Imprese_Esteso = (data) => {
return APIService.api().post(`/elenco_imprese_esteso/update.php`,data)
}
export const deleteElenco_Imprese_Esteso = (progressivo) => {
return APIService.api().post(`/elenco_imprese_esteso/delete.php`,{progressivo:progressivo})
}
