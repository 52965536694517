import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IRicerca_unica {
id_utente:number,
id_abbonamento:number,
codice_abbonamento:string,
numero_anagrafiche_massimo:number,
numero_anagrafiche_residuo:number,
data_apertura:Date,
data_scadenza:Date,
attivo:number
}

interface IRicerca_unicaData {
    list?: Array<any>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
    nextPage?: string;
    prevPage?: string;
    posizioniRicerca?: number;
}

const initialState: IRicerca_unicaData = {
    pageNo: 1,
    pageSize: 100,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
    nextPage: '',
    prevPage: '',
    posizioniRicerca: 0

};

const ricercaUnicaSlice = createSlice({
    name: "ricerca_unica",
    initialState,
    reducers: {
        setRicercaUnicaDataList: (state, _action: PayloadAction<IRicerca_unicaData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
            state.nextPage = _action.payload.nextPage;
            state.prevPage = _action.payload.prevPage;
            state.posizioniRicerca = _action.payload.posizioniRicerca;
        },
        resetRicercaUnicaDatatoInit: (state) => {
            state = initialState;
        },
        setRicercaUnicaDataMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setRicercaUnicaDataList, resetRicercaUnicaDatatoInit, setRicercaUnicaDataMessage } = ricercaUnicaSlice.actions;

export default ricercaUnicaSlice.reducer;

