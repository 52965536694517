import { APIService } from "services";

export const getLogin = async (pageNo,pageSize,search) => {
    let res;
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")
    
    if(search.length===0) {
        

        if(tipoUtente === "ADMIN") res =res = await getAllLogin(pageNo,pageSize);
        
    }
    else{
        try {
            res = await searchLogin(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getLoginByIdRegistrante = async (id,pageno,pagesize) => {
    
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    let res;
    res = await getLoginAzienda(id,pageno,pagesize);
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllLogin = (pageno,pagesize) => {
return APIService.api().get(`/login/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getLoginAzienda = (id,pageno,pagesize) => {
return APIService.api().get(`/login/read_login_azienda.php?azienda=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneLogin = (id) => {
return APIService.api().get(`/login/read_one.php?id=${id}`)
}
export const searchLogin = (key,pageno,pagesize) => {
return APIService.api().get(`/login/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addLogin = (data) => {
return APIService.api().post(`/login/create.php`,data)
}
export const updateLogin = (data) => {
return APIService.api().post(`/login/update.php`,data)
}
export const deleteLogin = (id) => {
return APIService.api().post(`/login/delete.php`,{id:id})
}
