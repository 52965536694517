import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setArchivioMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addArchivio, updateArchivio } from "services/archivioService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const ArchivioForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id_archivio:'',id_utente:'',id_prodotto:'',data_archivio:'',costo:'',quantita_addebitata:'',id_ticket:'',anagrafica:'',file:'',lettura:'',addebitare:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateArchivio(values);
                if (response) {
                    dispatch(setArchivioMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setArchivioMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addArchivio(values);
                if (response) {
                    dispatch(setArchivioMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setArchivioMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           id_utente: yup.number().required('id_utente is required'),
id_prodotto: yup.number().required('id_prodotto is required'),
data_archivio: yup.date().required('data_archivio is required'),
costo: yup.number().required('costo is required'),
quantita_addebitata: yup.number().required('quantita_addebitata is required'),
id_ticket: yup.string().required('id_ticket is required'),
anagrafica: yup.string().required('anagrafica is required'),
file: yup.string().required('file is required'),
lettura: yup.number().required('lettura is required'),
addebitare: yup.number().required('addebitare is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Archivio
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Archivio</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">id_utente</label>
<Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
></Form.Control>
{
    formik.errors.id_utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_prodotto</label>
<Form.Control type="text" name="id_prodotto" className="form-control" value={formik.values.id_prodotto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_prodotto && !!formik.errors.id_prodotto}
isValid ={!!formik.touched.id_prodotto && !formik.errors.id_prodotto}
></Form.Control>
{
    formik.errors.id_prodotto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_prodotto}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data_archivio</label>
<Form.Control type="text" name="data_archivio" className="form-control" value={formik.values.data_archivio}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data_archivio && !!formik.errors.data_archivio}
isValid ={!!formik.touched.data_archivio && !formik.errors.data_archivio}
></Form.Control>
{
    formik.errors.data_archivio && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data_archivio}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">costo</label>
<Form.Control type="text" name="costo" className="form-control" value={formik.values.costo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.costo && !!formik.errors.costo}
isValid ={!!formik.touched.costo && !formik.errors.costo}
></Form.Control>
{
    formik.errors.costo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.costo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">quantita_addebitata</label>
<Form.Control type="text" name="quantita_addebitata" className="form-control" value={formik.values.quantita_addebitata}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.quantita_addebitata && !!formik.errors.quantita_addebitata}
isValid ={!!formik.touched.quantita_addebitata && !formik.errors.quantita_addebitata}
></Form.Control>
{
    formik.errors.quantita_addebitata && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.quantita_addebitata}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">id_ticket</label>
<Form.Control type="text" name="id_ticket" className="form-control" value={formik.values.id_ticket}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_ticket && !!formik.errors.id_ticket}
isValid ={!!formik.touched.id_ticket && !formik.errors.id_ticket}
></Form.Control>
{
    formik.errors.id_ticket && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_ticket}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">anagrafica</label>
<Form.Control type="text" name="anagrafica" className="form-control" value={formik.values.anagrafica}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.anagrafica && !!formik.errors.anagrafica}
isValid ={!!formik.touched.anagrafica && !formik.errors.anagrafica}
></Form.Control>
{
    formik.errors.anagrafica && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.anagrafica}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">file</label>
<Form.Control type="text" name="file" className="form-control" value={formik.values.file}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.file && !!formik.errors.file}
isValid ={!!formik.touched.file && !formik.errors.file}
></Form.Control>
{
    formik.errors.file && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.file}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">lettura</label>
<Form.Control type="text" name="lettura" className="form-control" value={formik.values.lettura}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.lettura && !!formik.errors.lettura}
isValid ={!!formik.touched.lettura && !formik.errors.lettura}
></Form.Control>
{
    formik.errors.lettura && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.lettura}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">addebitare</label>
<Form.Control type="text" name="addebitare" className="form-control" value={formik.values.addebitare}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.addebitare && !!formik.errors.addebitare}
isValid ={!!formik.touched.addebitare && !formik.errors.addebitare}
></Form.Control>
{
    formik.errors.addebitare && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.addebitare}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

