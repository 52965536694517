import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setDati_UtenteMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addDati_Utente, updateDati_Utente } from "services/dati_utenteService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { parseJwtToken } from "services/tokenService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Dati_UtenteForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    const iValue={id:'',id_utente:jwtToken.data.id ,codice_univoco:'',pec:'',indirizzo:'',citta:'',provincia:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateDati_Utente(values);
                if (response) {
                    dispatch(setDati_UtenteMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setDati_UtenteMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addDati_Utente(values);
                if (response) {
                    dispatch(setDati_UtenteMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setDati_UtenteMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           //id_utente: yup.number().required('id_utente is required'),
codice_univoco: yup.string().required('codice_univoco is required'),
pec: yup.string().required('pec is required'),
indirizzo: yup.string().required('indirizzo is required'),
citta: yup.string().required('citta is required'),
provincia: yup.string().required('provincia is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h4 className="">Dati Utente Fatturazione
                    {/* <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Dati_Utente</span>
                    </Button> */}
                    <Button variant="info" className="float-right"  onClick={() => hideShowForm(false)}><i className="fa-solid fa-long-arrow-left"></i> Lista</Button>
                </h4>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
{/* <label className="form -control-label">id_utente</label> */}
{/* <Form.Control type="text" name="id_utente" className="form-control" value={formik.values.id_utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_utente && !!formik.errors.id_utente}
isValid ={!!formik.touched.id_utente && !formik.errors.id_utente}
></Form.Control> */}
{/* {
    formik.errors.id_utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_utente}
    </Form.Control.Feedback>
)} */}
</Form.Group>
<Form.Group>
<label className="form -control-label">codice_univoco</label>
<Form.Control type="text" name="codice_univoco" className="form-control" value={formik.values.codice_univoco}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codice_univoco && !!formik.errors.codice_univoco}
isValid ={!!formik.touched.codice_univoco && !formik.errors.codice_univoco}
></Form.Control>
{
    formik.errors.codice_univoco && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codice_univoco}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">pec</label>
<Form.Control type="text" name="pec" className="form-control" value={formik.values.pec}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.pec && !!formik.errors.pec}
isValid ={!!formik.touched.pec && !formik.errors.pec}
></Form.Control>
{
    formik.errors.pec && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.pec}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">indirizzo</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">citta</label>
<Form.Control type="text" name="citta" className="form-control" value={formik.values.citta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.citta && !!formik.errors.citta}
isValid ={!!formik.touched.citta && !formik.errors.citta}
></Form.Control>
{
    formik.errors.citta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.citta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">provincia</label>
<Form.Control type="text" name="provincia" className="form-control" value={formik.values.provincia}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.provincia && !!formik.errors.provincia}
isValid ={!!formik.touched.provincia && !formik.errors.provincia}
></Form.Control>
{
    formik.errors.provincia && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.provincia}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-left" variant="success">Conferma</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

