import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IWs_Prodotti {
id:number,
nome_prodotto:string,
categoria:string,
costo:number
}

interface IWs_ProdottiData {
    list?: Array<IWs_Prodotti>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IWs_ProdottiData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ws_prodottiSlice = createSlice({
    name: "ws_prodotti",
    initialState,
    reducers: {
        setWs_ProdottiList: (state, _action: PayloadAction<IWs_ProdottiData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetWs_ProdottiToInit: (state) => {
            state = initialState;
        },
        setWs_ProdottiMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setWs_ProdottiList, resetWs_ProdottiToInit, setWs_ProdottiMessage } = ws_prodottiSlice.actions;

export default ws_prodottiSlice.reducer;

