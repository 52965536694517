import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IDati_Utente {
id:number,
id_utente:number,
codice_univoco:string,
pec:string,
indirizzo:string,
citta:string,
provincia:string
}

interface IDati_UtenteData {
    list?: Array<IDati_Utente>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IDati_UtenteData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const dati_utenteSlice = createSlice({
    name: "dati_utente",
    initialState,
    reducers: {
        setDati_UtenteList: (state, _action: PayloadAction<IDati_UtenteData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetDati_UtenteToInit: (state) => {
            state = initialState;
        },
        setDati_UtenteMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setDati_UtenteList, resetDati_UtenteToInit, setDati_UtenteMessage } = dati_utenteSlice.actions;

export default dati_utenteSlice.reducer;

