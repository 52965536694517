import { APIService } from "services";
import { parseJwtToken } from "./tokenService";

export const getPiano_Abbonamento = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllPiano_Abbonamento(pageNo,pageSize);
    }
    else{
        try {
            res = await searchPiano_Abbonamento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getPiano_Abbonamento_Utente = async (pageNo,pageSize,search, idUtente) => {
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    if(jwtToken)
    {
        idUtente = jwtToken.data.id;
    }else{
        idUtente = 0; 
    }
    
    let res;
    if(search.length===0) {
        res = await getPiano_Abbonamento_Id_Utente(idUtente);
    }
    else{
        try {
            res = await searchPiano_Abbonamento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getPiano_Abbonamento_Utente_Attivo = async (pageNo,pageSize,search, idUtente) => {
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    if(jwtToken)
    {
        idUtente = jwtToken.data.id;
    }else{
        idUtente = 0; 
    }
    
    let res;
    if(search.length===0) {
        res = await getPiano_Abbonamento_Id_Utente_Attivo(idUtente);
    }
    else{
        try {
            res = await searchPiano_Abbonamento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getPiano_Abbonamento_Utente_Disattivo = async (pageNo,pageSize,search, idUtente) => {
    const token = localStorage.getItem('token');
    const jwtToken = parseJwtToken(token);
    if(jwtToken)
    {
        idUtente = jwtToken.data.id;
    }else{
        idUtente = 0; 
    }
    
    let res;
    if(search.length===0) {
        res = await getPiano_Abbonamento_Id_Utente_Disattivo(idUtente);
    }
    else{
        try {
            res = await searchPiano_Abbonamento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllPiano_Abbonamento = (pageno,pagesize) => {
return APIService.api().get(`/piano_abbonamento/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOnePiano_Abbonamento = (id) => {
return APIService.api().get(`/piano_abbonamento/read_one.php?id=${id}`)
}
export const getPiano_Abbonamento_Id_Utente = (idUtente) => {
return APIService.api().get(`/piano_abbonamento/read_piano.php?rifPage=${idUtente}`)
}

export const getPiano_Abbonamento_Id_Utente_Attivo = (idUtente) => {
return APIService.api().get(`/piano_abbonamento/read_piano_attivo_user.php?rifPage=${idUtente}`)
}

export const getPiano_Abbonamento_Id_Utente_Disattivo = (idUtente) => {
return APIService.api().get(`/piano_abbonamento/read_piano_disattivo_user.php?rifPage=${idUtente}`)
}

export const searchPiano_Abbonamento = (key,pageno,pagesize) => {
return APIService.api().get(`/piano_abbonamento/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addPiano_Abbonamento = (data) => {
return APIService.api().post(`/piano_abbonamento/create.php`,data)
}
export const updatePiano_Abbonamento = (data) => {
return APIService.api().post(`/piano_abbonamento/update.php`,data)
}
export const deletePiano_Abbonamento = (id_utente,id_abbonamento) => {
return APIService.api().post(`/piano_abbonamento/delete.php`,{id_utente:id_utente,id_abbonamento:id_abbonamento})
}
