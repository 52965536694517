import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setWs_ProdottiMessage } from "redux/actions";


import { useAppDispatch } from "redux/store";
import { addWs_Prodotti, updateWs_Prodotti } from "services/ws_prodottiService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Ws_ProdottiForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',nome_prodotto:'',categoria:'',costo:''};
    const initialValue = action === 'edit' ? row : iValue;
    
    
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateWs_Prodotti(values);
                if (response) {
                    dispatch(setWs_ProdottiMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_ProdottiMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addWs_Prodotti(values);
                if (response) {
                    dispatch(setWs_ProdottiMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setWs_ProdottiMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           nome_prodotto: yup.string().required('nome_prodotto is required'),
categoria: yup.string().required('categoria is required'),
costo: yup.number().required('costo is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary text-capitalize">{action} Ws_Prodotti
                    <Button className="btn-icon-split float-right" onClick={() => hideShowForm(false)}>
                        <span className="icon text-white-50">
                            <i className="fas fa-list"></i>
                        </span>
                        <span className="text">View Ws_Prodotti</span>
                    </Button>
                </h6>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">nome_prodotto</label>
<Form.Control type="text" name="nome_prodotto" className="form-control" value={formik.values.nome_prodotto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome_prodotto && !!formik.errors.nome_prodotto}
isValid ={!!formik.touched.nome_prodotto && !formik.errors.nome_prodotto}
></Form.Control>
{
    formik.errors.nome_prodotto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome_prodotto}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">categoria</label>
<Form.Control type="text" name="categoria" className="form-control" value={formik.values.categoria}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.categoria && !!formik.errors.categoria}
isValid ={!!formik.touched.categoria && !formik.errors.categoria}
></Form.Control>
{
    formik.errors.categoria && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.categoria}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">costo</label>
<Form.Control type="text" name="costo" className="form-control" value={formik.values.costo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.costo && !!formik.errors.costo}
isValid ={!!formik.touched.costo && !formik.errors.costo}
></Form.Control>
{
    formik.errors.costo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.costo}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

