import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IOrdini {
id:number,
data?:Date,
metodo_di_pagamento?:string,
prezzo_totale?:number,
stato_ordine?:number,
id_abbonamento?:number,
id_utente?:number,
id_login?:number
}

interface IOrdiniData {
    list?: Array<IOrdini>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IOrdiniData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const ordiniSlice = createSlice({
    name: "ordini",
    initialState,
    reducers: {
        setOrdiniList: (state, _action: PayloadAction<IOrdiniData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetOrdiniToInit: (state) => {
            state = initialState;
        },
        setOrdiniMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setOrdiniList, resetOrdiniToInit, setOrdiniMessage } = ordiniSlice.actions;

export default ordiniSlice.reducer;

