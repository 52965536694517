import { APIService } from "services";

export const getDati_Utente = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllDati_Utente(pageNo,pageSize);
    }
    else{
        try {
            res = await searchDati_Utente(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllDati_Utente = (pageno,pagesize) => {
return APIService.api().get(`/dati_utente/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneDati_Utente = (id) => {
return APIService.api().get(`/dati_utente/read_one.php?id=${id}`)
}
export const getOneDati_UtentebyidUtente = (id) => {
return APIService.api().get(`/dati_utente/read_one_by_id_user.php?id=${id}`)
}
export const searchDati_Utente = (key,pageno,pagesize) => {
return APIService.api().get(`/dati_utente/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addDati_Utente = (data) => {
return APIService.api().post(`/dati_utente/create.php`,data)
}
export const updateDati_Utente = (data) => {
return APIService.api().post(`/dati_utente/update.php`,data)
}
export const deleteDati_Utente = (id) => {
return APIService.api().post(`/dati_utente/delete.php`,{id:id})
}
